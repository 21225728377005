import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import HoL7ymgj from "./HoL7ymgj";

import translationData from "../assets/data/translation.json";

const EXyVyy7R = ({ isMobile, isTablet }) => {
	const { geoCode, lang } = useParams();
	const [geoData, setGeoData] = useState(null);
	const [translation, settranslation] = useState(null);

	useEffect(() => {
		const fetchGeoData = async () => {
			try {
				const response = await fetch(`/geo/data/${geoCode}.json`);
				if (!response.ok) {
				}
				const data = await response.json();
				setGeoData(data);
			} catch (error) {}
		};

		fetchGeoData();
	}, [geoCode, lang]);

	const loadCSS = (href) => {
		const existingLink = document.getElementById("dynamic-css");
		if (existingLink) {
			existingLink.remove();
		}

		const link = document.createElement("link");
		link.rel = "stylesheet";
		link.href = href;
		link.id = "dynamic-css";
		document.head.appendChild(link);
	};

	useEffect(() => {
		let cssHref;

		switch (lang) {
			case "ar":
				cssHref = "/assets/css/Ewrmmu0LiFUj3kJMgDCLwbILAqH3Pr4V.css";
				break;
			case "en":
			default:
				cssHref = "/assets/css/DL2SD2EKQYqf6ygoTQqRg4djNpPW51BQ.css";
				break;
		}

		loadCSS(cssHref);
		settranslation(translationData);
	}, [lang]);

	if (!geoData || !translation) {
		return null;
	}

	return (
		<HoL7ymgj
			geoData={geoData}
			translation={translation}
			lang={lang}
			geoCode={geoCode}
			isMobile={isMobile}
			isTablet={isTablet}
		/>
	);
};

export default EXyVyy7R;
