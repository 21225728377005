import React, { useState } from "react";
import P83xQfm8 from "./P83xQfm8";

const DTYYRYEP = ({
	geoData,
	translation,
	lang,
	geoCode,
	isMobile,
	isTablet,
	onRouteChange,
}) => {
	const [activeTab, setActiveTab] = useState("promotions");
	const sortedRestaurantsRate = [...geoData.restaurants].sort(
		(a, b) => b.globalRate - a.globalRate
	);

	const sortedRestaurantsDefault = geoData.restaurants;

	const renderContent = () => {
		switch (activeTab) {
			case "promotions":
				return (
					<P83xQfm8
						translation={translation}
						lang={lang}
						restaurants={sortedRestaurantsRate}
						onRouteChange={onRouteChange}
						geoCode={geoCode}
					/>
				);
			case "coupons":
				return (
					<P83xQfm8
						translation={translation}
						lang={lang}
						restaurants={sortedRestaurantsDefault}
						onRouteChange={onRouteChange}
						geoCode={geoCode}
					/>
				);

			default:
				return null;
		}
	};

	return (
		<div className="gFS8VVb5">
			<div className="EwzAHC1c">
				<div className="fizYaa3q W4G7D10n">
					<div
						className={`ZHcWH8k1 fV3xBBmH ${
							activeTab === "promotions" ? "active" : ""
						}`}
						id={activeTab === "promotions" ? "ldDWGbO9" : ""}
						onClick={() => setActiveTab("promotions")}
					>
						<img className="VBscvKRg" src="/assets/img/odQm4pk1.webp" />
						<span className="tYfB00jd">{translation.Vbvb9LFq[lang]}</span>
					</div>

					<div
						className={`ZHcWH8k1 TOT0z5J8 ${
							activeTab === "coupons" ? "active" : ""
						}`}
						id={activeTab === "coupons" ? "ldDWGbO9" : ""}
						onClick={() => setActiveTab("coupons")}
					>
						<img className="VBscvKRg" src="/assets/img/vzvUwYca.webp" />
						<span className="tYfB00jd">{translation.DhNWNh9J[lang]}</span>
					</div>
				</div>
			</div>

			<div className="LcmRSd6D">{renderContent()}</div>
		</div>
	);
};

export default DTYYRYEP;
