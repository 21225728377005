import React from "react";

const GiMvkzcg = ({
	geoCode,
	geoData,
	isMobile,
	isTablet,
	translation,
	lang,
}) => {
	return (
		<div className="slMIYwCG">
			<div className="u1M2qEeU">
				<div className="RCmRZMnT">
					<img className="DX3HNFRV" src="/assets/img/cqt9VLJw.svg"></img>
					<span className="J2aygjcR">https://</span>
				</div>
				<span className="kl5TYAMJ">{translation.SN9Qu0M7[lang]}</span>
			</div>
		</div>
	);
};

export default GiMvkzcg;
