import React, {
	useState,
	useEffect,
	useRef,
	useCallback,
	useMemo,
} from "react";
import W59X5NYD from "./W59X5NYD";

const ZJHfBout = ({
	geoData,
	restaurantData,
	translation,
	lang,
	isMobile,
	isTablet,
}) => {
	const { categories, products } = restaurantData.menu;

	const initialExpandedState = useMemo(
		() =>
			categories.reduce((acc, category) => {
				acc[category.id] = !isMobile;
				return acc;
			}, {}),
		[categories]
	);

	const [expandedCategories, setExpandedCategories] =
		useState(initialExpandedState);
	const [searchQuery, setSearchQuery] = useState("");
	const categoryRefs = useRef({});

	const filteredMenu = useMemo(() => {
		if (!searchQuery) return categories;

		const query = searchQuery.toLowerCase();
		return categories
			.map((category) => {
				const filteredProductIds = category.productIds.filter((productId) => {
					const product = products.find((p) => p.id === productId);
					return product && product.name[lang].toLowerCase().includes(query);
				});

				return {
					...category,
					productIds: filteredProductIds,
				};
			})
			.filter((category) => category.productIds.length > 0);
	}, [searchQuery, categories, products, lang]);

	const handleSearchChange = (event) => {
		const newQuery = event.target.value;
		setSearchQuery(newQuery);

		if (!newQuery) {
			setExpandedCategories(initialExpandedState);
		}
	};

	const handleClearInput = () => {
		setSearchQuery("");
		setExpandedCategories(initialExpandedState);
	};

	const toggleCategory = useCallback((categoryId) => {
		setExpandedCategories((prev) => ({
			...prev,
			[categoryId]: !prev[categoryId],
		}));
	}, []);

	const scrollToCategory = useCallback((categoryId) => {
		const categoryElement = categoryRefs.current[categoryId];
		if (categoryElement) {
			window.scrollTo({
				top: categoryElement.offsetTop - 20,
				behavior: "smooth",
			});
		}
	}, []);

	return (
		<div className="dB39sn1c">
			<div className="XyKebsu7">
				<div className="JBcQBzZy">
					<span className="EXyVyy7R">{translation.Xp0dt6Fb[lang]}</span>
					<div className="dF5yk3rV">
						{filteredMenu.map((category) => (
							<div
								className="usygeVd5"
								key={category.id}
								onClick={() => scrollToCategory(category.id)}
							>
								<span key={category.id} className="VC5qO4RK">
									{category.name[lang]}
								</span>
								<div className="oJdLVJ8h">
									<div className="TPMTkiwo">
										<span key={category.id} className="IGTbxlfC">
											{category.name[lang]}
										</span>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
			<div className="VJqNtVgM">
				<div className="IrVVVTWh">
					<input
						className="qU9m9bH4"
						type="text"
						placeholder={translation.OmLoJNZO[lang]}
						value={searchQuery}
						onChange={handleSearchChange}
					/>
					<div className="y0fk4Xmu">
						<img
							src="/assets/img/MuIvH6OY.svg"
							className={`OYcATGd1 ORXJm7Bz ${
								searchQuery.length === 0 ? "u4x0guHL" : ""
							}`}
						/>

						<img
							src="/assets/img/NWdnAkbi.svg"
							className={`OYcATGd1 kMMvmWk4 ${
								searchQuery.length !== 0 ? "u4x0guHL" : ""
							}`}
							onClick={handleClearInput}
						/>
					</div>
				</div>
				<div className="P5NNMmzh">
					{filteredMenu.map((category) => {
						const isExpanded = expandedCategories[category.id];

						return (
							<div
								key={category.id}
								className="IA89U1VO"
								ref={(el) => (categoryRefs.current[category.id] = el)}
							>
								<div
									className="iK7rKafV"
									onClick={() => toggleCategory(category.id)}
								>
									<span className="iMTZPjwh">{category.name[lang]}</span>
									<img
										className={`fbq9BKxT ${
											isExpanded ? "b7d8c5e3" : "c2e4f8a9"
										}`}
										src="/assets/img/L7dPzOzi.svg"
									/>
								</div>
								<div
									className={`aZE8UflA ${isExpanded ? "f3a9d2c4" : "e2b1a8f9"}`}
								>
									{category.productIds.map((productId) => {
										const product = products.find((p) => p.id === productId);

										return product ? (
											<W59X5NYD
												key={product.id}
												product={product}
												geoData={geoData}
												lang={lang}
												translation={translation}
												restaurantData={restaurantData}
											/>
										) : null;
									})}
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default ZJHfBout;
