import React from "react";
import WznWrZnG from "./WznWrZnG";
import { CartProvider } from "../contexts/CaRTCxqNL";

const HoL7ymgj = ({
	geoCode,
	geoData,
	isMobile,
	isTablet,
	translation,
	lang,
}) => {
	return (
		<CartProvider>
			<WznWrZnG
				geoData={geoData}
				translation={translation}
				lang={lang}
				geoCode={geoCode}
				isMobile={isMobile}
				isTablet={isTablet}
			/>
		</CartProvider>
	);
};

export default HoL7ymgj;
