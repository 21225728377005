import React from "react";

function PlyuX3Hq() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="11"
			height="11"
			fill="none"
			viewBox="0 0 11 11"
			className="fFRIXQWx"
		>
			<path
				fill="#676767"
				d="M5.6.681A4.843 4.843 0 00.756 5.525 4.843 4.843 0 005.6 10.369a4.843 4.843 0 004.844-4.844A4.843 4.843 0 005.6.68zM3.022 6.306a.235.235 0 01-.235-.234V4.978c0-.129.106-.234.235-.234h5.156c.129 0 .234.105.234.234v1.094a.235.235 0 01-.234.234H3.022z"
			></path>
		</svg>
	);
}

export default PlyuX3Hq;
