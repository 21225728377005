import React from "react";
import FafrGkG5 from "./FafrGkG5";
import { IPProvider } from "../contexts/OgbDavXL.js";

const NnhW6Auh = () => {
	return (
		<IPProvider>
			<FafrGkG5 />
		</IPProvider>
	);
};

export default NnhW6Auh;
