import React from "react";

const RdlKuRQz = ({ geoData, paymentMethods }) => {
	return (
		<div className="teWXMBOt">
			{geoData.paymentMethods
				.filter((method) => method.status === "enabled")
				.map((method) => {
					switch (method.id) {
						case "qhfJmnbotAVnEOCtDx4czfCowB1bTlYy":
							return (
								<div className="xroy2yoQ specific-class-1" key={method.id}>
									<img className="YrNrxaaE" src="/assets/img/xI34d24S.png" />
									<img className="YrNrxaaE" src="/assets/img/gmZJcWLX.svg" />
								</div>
							);
						case "pvWYUKLFgYkK6uXdBMGPeRbAGIVwPl4w":
							return (
								<div className="xroy2yoQ specific-class-2" key={method.id}>
									<img className="YrNrxaaE" src="/assets/img/mxX3TO1f.png" />
								</div>
							);
						case "LnzMYikHZKPrn0p5SotFvCtGRCmRZMnT":
							return (
								<div className="xroy2yoQ specific-class-3" key={method.id}>
									<img className="YrNrxaaE" src="/assets/img/AazOG6zI.svg" />
								</div>
							);
						default:
							return null;
					}
				})}
		</div>
	);
};

export default RdlKuRQz;
