import React from "react";
import TGzLYVAu from "./TGzLYVAu";

const RMNcN5iT = ({
	geoData,
	geoCode,
	translation,
	lang,
	onRouteChange,
	restaurantData,
	cart,
}) => {
	const restaurant = geoData.restaurants.find(
		(restaurant) => restaurant.id === restaurantData.id
	);

	const handleRedirectRestaurant = () => {
		onRouteChange(`/${lang}/${geoCode}/restaurant/${restaurantData.id}`);
	};
	return (
		<div className="r9tdXEil">
			<div className="QO9IKp6D">
				<span className="ljPn3lNI">{translation.FPsaBm2G[lang]}</span>

				<span className="TrNPMabA" onClick={handleRedirectRestaurant}>
					{translation.QVJXYVcd[lang]}
				</span>
			</div>
			<div className="RVBw4yNw">
				<div className="ccB2c4J8">
					<span className="hMgJaiVD">{restaurant.name[lang]}</span>
				</div>
				<div className="vN01c5nA">
					<div className="hnNN1f1f">
						<span className="RioSpX8O GE2NmF8B VoGGcpA5">
							{translation.u6Yb3Orp[lang]}
						</span>

						<span className="RioSpX8O L2yFRDK7 VoGGcpA5">
							{translation.piYUXLbh[lang]}
						</span>

						<span className="RioSpX8O L2yFRDK7 VoGGcpA5">
							{translation.cDdJIBaz[lang]}
						</span>

						<span className="RioSpX8O L2yFRDK7 VoGGcpA5">
							{translation.jjxh7qvk[lang]}
						</span>
					</div>

					<div className="vN01c5nA jbnNrRcF">
						{cart.items.map((item) => {
							const product = restaurantData.menu.products.find(
								(p) => p.id === item.id
							);
							if (!product) return null;

							const price = product.price.toFixed(2);
							const totalPrice = (product.price * item.quantity).toFixed(2);

							return (
								<div key={item.id} className="fKp9q89j">
									<span className="nMJee9oM GE2NmF8B j5FA8UEE">
										{product.name[lang]}
									</span>

									<span className="nMJee9oM L2yFRDK7 ktt7p5t8 VoGGcpA5">
										{item.quantity}
									</span>

									<span className="nMJee9oM L2yFRDK7 ktt7p5t8 VoGGcpA5">
										{geoData.currency[lang]} {price}
									</span>

									<span className="nMJee9oM L2yFRDK7 ktt7p5t8 VoGGcpA5">
										{geoData.currency[lang]} {totalPrice}
									</span>
								</div>
							);
						})}
					</div>
				</div>
				<div className="dOvWR0Jm F1NOVAoJ">
					<TGzLYVAu />
					<span className="zOe62coM">{translation.OA8YOZ2g[lang]}</span>
					<div className="MPRFxGjs">
						<img className="kDZikyt0" src="/assets/img/HFyw7HTT.svg"></img>
					</div>
				</div>
			</div>
		</div>
	);
};

export default RMNcN5iT;
