import React, { useState, useEffect } from "react";
import { useCart } from "../contexts/CaRTCxqNL";
import PlyuX3Hq from "./PlyuX3Hq";
import QlhjIlkd from "./QlhjIlkd";

const MwawnQAy = ({
	geoCode,
	geoData,
	isMobile,
	isTablet,
	translation,
	lang,
	onClose,
	onRouteChange,
}) => {
	const {
		cart,
		currentRestaurantData,
		removeItemFromCart,
		addItemToCart,
		removeAllItemsById,
	} = useCart();
	const [loading, setLoading] = useState(false);

	const handleCheckoutClick = () => {
		onRouteChange(`/${lang}/${geoCode}/checkout/`);
	};

	const handleAddItemToCart = (itemId, restaurantId) => {
		setLoading(true);
		setTimeout(() => {
			addItemToCart(itemId, restaurantId);
			setLoading(false);
		}, 1000);
	};

	const handleRemoveItemFromCart = (itemId) => {
		setLoading(true);
		setTimeout(() => {
			removeItemFromCart(itemId);
			setLoading(false);
		}, 1000);
	};

	const handleRemoveAllItemsById = (itemId) => {
		setLoading(true);
		setTimeout(() => {
			removeAllItemsById(itemId);
			setLoading(false);
		}, 1000);
	};

	useEffect(() => {
		setLoading(true);
		const timer = setTimeout(() => {
			setLoading(false);
		}, 1000);

		return () => clearTimeout(timer);
	}, []);

	if (!cart.id || cart.items.length === 0) {
		return null;
	}

	if (!currentRestaurantData) {
		return null;
	}

	const getRestaurantName = () => {
		const restaurant = geoData.restaurants.find((r) => r.id === cart.id);
		return restaurant ? restaurant.name[lang] : "";
	};

	const getTotalPrice = () => {
		return cart.items.reduce((total, item) => {
			const product = currentRestaurantData.menu.products.find(
				(p) => p.id === item.id
			);
			return total + (product ? product.price * item.quantity : 0);
		}, 0);
	};

	const getTotalDiscountedPrice = () => {
		return cart.items.reduce((total, item) => {
			const product = currentRestaurantData.menu.products.find(
				(p) => p.id === item.id
			);
			return total + (product ? product.discountedPrice * item.quantity : 0);
		}, 0);
	};

	const totalPrice = getTotalPrice();
	const totalDiscountedPrice = getTotalDiscountedPrice();
	const deliveryFee = currentRestaurantData.deliveryFee || 0;
	const serviceFee = currentRestaurantData.serviceFee || 0;
	const finalPrice = totalDiscountedPrice + serviceFee;

	return (
		<div className="mjbuviIE">
			<div className="nOSwXm8E">
				<div className="eIvmxHGl">
					{loading && <QlhjIlkd />}
					<div className="VImkeApa">
						<span className="fnBBaNUh">{translation.cZ1hl2jd[lang]}</span>
						<div className="osVouTtp" onClick={onClose}>
							<div
								className="F3Wh7gcV"
								style={{ backgroundImage: `url("/assets/img/Crk6K7yh.svg")` }}
							></div>
						</div>
					</div>
					<div className="ySaz8j58">
						<div className="hXufXrJc">
							<span className="acBALz96">{getRestaurantName()}</span>
						</div>

						<div className="bsCTPHqX">
							{cart.items.map((item) => {
								const product = currentRestaurantData.menu.products.find(
									(p) => p.id === item.id
								);
								if (!product) return null;

								return (
									<div key={item.id} className="DQQ0qFJv">
										<div className="x13sN4Pa">
											<div
												className="KtPBtRaq tIv42Aqb"
												onClick={() => handleRemoveItemFromCart(item.id)}
											>
												<div className="oIlunR7P">
													<img
														className="fFRIXQWx"
														src="/assets/img/c8Rpm83F.svg"
													/>
												</div>
											</div>

											<div className="PIRjFJlr">
												<span className="MkYvSgVP">{item.quantity}</span>
											</div>

											<div
												className="KtPBtRaq jvIswGG5"
												onClick={() => handleAddItemToCart(item.id, cart.id)}
											>
												<div className="oIlunR7P">
													<img
														className="fFRIXQWx"
														src="/assets/img/SFuOxzuV.svg"
													/>
												</div>
											</div>
										</div>

										<div className="muxFKJtO">
											<span className="F27WZGyP">{product.name[lang]} </span>
										</div>

										<div className="XWHyjaoY">
											<span className="rniEDlFW">
												{geoData.currency[lang]}{" "}
												{(product.price * item.quantity).toFixed(2)}
											</span>
										</div>

										<div className="tsmb0BsJ">
											<div
												className="oIlunR7P qIBlKEWQ"
												onClick={() => handleRemoveAllItemsById(item.id)}
											>
												<PlyuX3Hq />
											</div>
										</div>
									</div>
								);
							})}
						</div>

						<div className="wS5T3JBE">
							<div className="h9wvH0ZX">
								<div className="ytblgdua">
									<span className="aJGHcWIB eSaeV12L pph0IAM4">
										{translation.jftlTfmL[lang]}
									</span>
								</div>
								<span className="l4jpK1Vf pph0IAM4 pph0IAM4">
									{geoData.currency[lang]} {totalPrice.toFixed(2)}
								</span>
							</div>

							<div className="h9wvH0ZX">
								<div className="ytblgdua">
									<span className="aJGHcWIB eSaeV12L K4m7TUzP">
										{translation.E7JshcXg[lang]}
									</span>
								</div>
								<span className="l4jpK1Vf eSaeV12L K4m7TUzP">
									- {geoData.currency[lang]}{" "}
									{(totalPrice - totalDiscountedPrice).toFixed(2)}
								</span>
							</div>

							<div className="h9wvH0ZX">
								<div className="ytblgdua">
									<span className="aJGHcWIB eSaeV12L pph0IAM4">
										{translation.OeA482zx[lang]}
									</span>
									<div className="pRjq6JTU">
										<img className="S7Bs6UVT" src="/assets/img/XW0gTOVA.svg" />
									</div>
								</div>
								<span className="l4jpK1Vf eSaeV12L Fi23N6fG e1nt1aIX">
									{geoData.currency[lang]} {deliveryFee.toFixed(2)}
								</span>
							</div>

							<div className="h9wvH0ZX">
								<div className="ytblgdua">
									<span className="aJGHcWIB eSaeV12 pph0IAM4">
										{translation.uByJZWrM[lang]}
									</span>
									<div className="pRjq6JTU">
										<img className="S7Bs6UVT" src="/assets/img/XW0gTOVA.svg" />
									</div>
								</div>
								<span className="l4jpK1Vf eSaeV12L pph0IAM4">
									{geoData.currency[lang]} {serviceFee.toFixed(2)}
								</span>
							</div>

							<div className="h9wvH0ZX">
								<div className="ytblgdua">
									<span className="aJGHcWIB eSaeV12L NLOG9Krj">
										{translation.KaXfAOBo[lang]}
									</span>
								</div>
								<span className="l4jpK1Vf eSaeV12L NLOG9Krj">
									{geoData.currency[lang]} {finalPrice.toFixed(2)}
								</span>
							</div>

							<div className="KnCW3pvj CEzUuHLn" onClick={handleCheckoutClick}>
								<span className="tmt1Bw0C">{translation.WGvGBCd1[lang]}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MwawnQAy;
