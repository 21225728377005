import React from "react";
import DTYYRYEP from "./DTYYRYEP";

const A7TJHBY9 = ({
	geoCode,
	geoData,
	isMobile,
	isTablet,
	translation,
	lang,
	onRouteChange,
}) => {
	document.title = `${geoData.offers.huUdOg6f[lang]}`;

	return (
		<div className="lo6VRrhT">
			<div className="z47ukWTS vI19C5po">
				<div className="pRcUKBzy">
					<div className="rmbw6Mij">
						<span className="cSJOXr9J">{translation.OOpMbO9A[lang]}</span>
					</div>
					<div className="HUyU594u">
						<span className="OKo97AW5">{geoData.offers.XTBqjDuN[lang]}</span>
					</div>
					<DTYYRYEP
						geoData={geoData}
						translation={translation}
						lang={lang}
						geoCode={geoCode}
						onRouteChange={onRouteChange}
					/>
				</div>
			</div>
		</div>
	);
};

export default A7TJHBY9;
