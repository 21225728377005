import React from "react";
import VaPZm9F3 from "./VaPZm9F3";
import RMNcN5iT from "./RMNcN5iT";
import AJUHtCMt from "./AJUHtCMt";
import XkhXEdMv from "./XkhXEdMv";
import { useCart } from "../contexts/CaRTCxqNL";

const FlkuQlWY = ({
	geoCode,
	geoData,
	isMobile,
	isTablet,
	translation,
	lang,
	onRouteChange,
	paymentMethods,
}) => {
	const { cart, currentRestaurantData } = useCart();

	document.title = `${translation.mKhDeKdx[lang]} | ${translation.d1okXsBa[lang]}`;

	if (!geoData || !geoData.areas || !currentRestaurantData) {
		return null;
	}

	return (
		<div className="lo6VRrhT">
			<div className="z47ukWTS vI19C5po M1MAll7b">
				<VaPZm9F3
					restaurantData={currentRestaurantData}
					areas={geoData.areas}
					geoCode={geoCode}
					lang={lang}
					onRouteChange={onRouteChange}
					translation={translation}
					geoData={geoData}
				/>
				<RMNcN5iT
					restaurantData={currentRestaurantData}
					geoCode={geoCode}
					lang={lang}
					onRouteChange={onRouteChange}
					translation={translation}
					geoData={geoData}
					cart={cart}
				/>
				<AJUHtCMt
					restaurantData={currentRestaurantData}
					geoCode={geoCode}
					lang={lang}
					onRouteChange={onRouteChange}
					translation={translation}
					geoData={geoData}
				/>
				<XkhXEdMv
					restaurantData={currentRestaurantData}
					geoCode={geoCode}
					lang={lang}
					onRouteChange={onRouteChange}
					translation={translation}
					geoData={geoData}
					cart={cart}
					paymentMethods={paymentMethods}
				/>
			</div>
		</div>
	);
};

export default FlkuQlWY;
