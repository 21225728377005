import React from "react";

const VDyn8ZsI = ({ translation, lang, isMobile, geoCode, onRouteChange }) => {
	const handleButtonClick = () => {
		onRouteChange(`/${lang}/${geoCode}/`);
	};
	return (
		<div className="lpETD4AA">
			{isMobile ? (
				<img className="TIe7Wl9F" src="/assets/img/owaSIGad.webp" />
			) : (
				<img className="TIe7Wl9F" src="/assets/img/JOwJXjO0.webp" />
			)}

			<div className="erZ09rxA">
				<span className="U6myBVKU">{translation.YgrOWrCv[lang]}</span>
				<span className="HIIut1QO">{translation.DURdddNU[lang]}</span>

				<div className="YKNCEYDX" onClick={handleButtonClick}>
					<span className="NFNV1zGA">{translation.bCrb3S0Y[lang]}</span>
				</div>
			</div>
		</div>
	);
};

export default VDyn8ZsI;
