import React, { useState, useEffect } from "react";

const LvLjF4lF = ({
	columnIds,
	lang,
	geoCode,
	headerText,
	footerText,
	isMobile,
	columnData,
	navigateParams,
	onRouteChange,
}) => {
	const [isExpanded, setIsExpanded] = useState(!isMobile);

	const filteredItems = columnData
		.filter((item) => columnIds.includes(item.id))
		.slice(0, 5);

	useEffect(() => {
		setIsExpanded(!isMobile);
	}, [isMobile]);

	const handleHeaderClick = () => {
		if (isMobile) {
			setIsExpanded(!isExpanded);
		}
	};

	const handleRowClick = (itemId) => {
		onRouteChange(`/${lang}/${geoCode}/${navigateParams}/${itemId}/`);
	};

	const handleMoreClick = () => {
		onRouteChange(`/${lang}/${geoCode}/`);
	};

	return (
		<div className="q7Oa78X2">
			<div className="mTiQ3iCd" onClick={handleHeaderClick}>
				<span className="iWpiqyOz hZUkK8tz">{headerText[lang]}</span>
				{isMobile && (
					<div className="l8KqZiwd">
						<div
							className={`Hnd72gbh ${!isExpanded ? "" : "s7EumfLI"}`}
							onClick={(e) => {
								e.stopPropagation();
								setIsExpanded(true);
							}}
						>
							<img className="QqqgkDo8" src="/assets/img/kkK35xPi.svg" />
						</div>

						<div
							className={`Hnd72gbh ${isExpanded ? "" : "s7EumfLI"}`}
							onClick={(e) => {
								e.stopPropagation();
								setIsExpanded(false);
							}}
						>
							<img className="QqqgkDo8" src="/assets/img/BSuLegkF.svg" />
						</div>
					</div>
				)}
			</div>
			<div className="NgtD1lpo">
				<div
					className={`GeL2ibKb ${isMobile && !isExpanded ? "mMhkTfKZ" : ""}`}
				>
					{filteredItems.map((item) => (
						<span
							key={item.id}
							className="vLrFqnA9"
							onClick={() => handleRowClick(item.id)}
						>
							{item.name[lang]}
						</span>
					))}
					<span className="ykTnLK5Y" onClick={handleMoreClick}>
						{footerText[lang]}
					</span>
				</div>
			</div>
		</div>
	);
};

export default LvLjF4lF;
