import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import NnhW6Auh from "./components/NnhW6Auh";

import "./assets/css/fonts.css";
import "./assets/css/styles.css";

function App() {
	return (
		<Router>
			<Routes>
				<Route path="/:lang/:geoCode/*" element={<NnhW6Auh />} />
			</Routes>
		</Router>
	);
}

export default App;
