import React from "react";
import { useLocation } from "react-router-dom";
import MY52L4c6 from "./MY52L4c6";

const VRF6GlW0 = ({ geoCode, geoData, translation, lang, onRouteChange }) => {
	const location = useLocation();

	const handleLanguageChange = (newLang) => {
		if (newLang !== lang) {
			const newUrl = location.pathname.replace(`/${lang}/`, `/${newLang}/`);

			onRouteChange(newUrl);
		}
	};

	const handleRedirect = () => {
		onRouteChange(`/${lang}/${geoCode}/`);
	};

	return (
		<div className="BHiLxHv2">
			<div className="rSBpJezs">
				<div className="vdGxVobn">
					<img className="FEPqBvdN" src="/assets/img/EbtJQEz9.svg"></img>
					<div className="odc39irv">
						{lang === "ar" ? (
							<>
								<span
									className="nnT64A0y"
									style={{ cursor: "pointer" }}
									onClick={() => handleLanguageChange("en")}
								>
									English
								</span>
							</>
						) : (
							<>
								<span
									className="nnT64A0y"
									style={{ cursor: "pointer" }}
									onClick={() => handleLanguageChange("ar")}
								>
									العربية
								</span>
							</>
						)}
					</div>
				</div>
				<div className="eyl5nyga" onClick={handleRedirect}>
					<div
						className="s4xsX8HR"
						style={{ backgroundImage: `url('/assets/img/Xzdh61Of.svg')` }}
					></div>
				</div>
				<div className="Gi3E0gQU">
					<div className="J9GdvreC">
						<div
							className="bgtoLZ1i"
							style={{ backgroundImage: `url(/geo/img/${geoData.img})` }}
						></div>
					</div>
					<MY52L4c6 />
				</div>
			</div>
		</div>
	);
};

export default VRF6GlW0;
