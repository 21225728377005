import React from "react";
import { useCart } from "../contexts/CaRTCxqNL";

const JeteE9e8 = ({
	geoData,
	geoCode,
	isMobile,
	isTablet,
	translation,
	lang,
	onShowMwawnQAy,
}) => {
	const { cart, currentRestaurantData } = useCart();

	const totalDiscountedPrice = cart.items.reduce((total, item) => {
		const product = currentRestaurantData?.menu?.products.find(
			(p) => p.id === item.id
		);
		const discountedPrice = product
			? product.discountedPrice * item.quantity
			: 0;
		return total + discountedPrice;
	}, 0);

	const shouldDisplay = cart.items.length > 0 && (isTablet || isMobile);

	if (!shouldDisplay) return null;

	const handleClick = () => {
		onShowMwawnQAy();
	};

	return (
		<div className="Ekb60ujB">
			<div className="rRulR5ZB">
				<span className="WIXkYLzH">{`${translation.Knc82DVo[lang]}: ${
					geoData.currency[lang]
				} ${totalDiscountedPrice.toFixed(2)}`}</span>
				<div className="KnCW3pvj lnfUQdC1" onClick={handleClick}>
					<span className="WIXkYLzH QERlDCR0">
						{translation.Mz9GJbno[lang]}
					</span>
				</div>
			</div>
		</div>
	);
};

export default JeteE9e8;
