import React from "react";

const QlhjIlkd = ({}) => {
	return (
		<div className="DhZJrjtE">
			<div className="F1amuIqt">
				<img className="n7nbFddi" src="/assets/img/w4D5oVuB.svg"></img>
			</div>

			<div className="DRphFvSw"></div>
		</div>
	);
};

export default QlhjIlkd;
