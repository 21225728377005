import React, { useState } from "react";
import SaW5FYZh from "./SaW5FYZh";
import { useCart } from "../contexts/CaRTCxqNL";
import STXOf2f5 from "./STXOf2f5";

const W59X5NYD = ({
	product,
	geoData,
	lang,
	translation,
	restaurantData,
	isMobile,
}) => {
	const { addItemToCart } = useCart();
	const [isLoading, setIsLoading] = useState(false);

	const handleAddToCart = () => {
		setIsLoading(true);
		setTimeout(() => {
			setIsLoading(false);
			addItemToCart(product.id, restaurantData.id);
		}, 2000);
	};

	return (
		<div key={product.id} className="duqMUb5V">
			<SaW5FYZh product={product} />

			<div className="lAWDbLSz">
				<span className="ngKb3LKd">{product.name[lang]}</span>
				<span className="dXshU0Cm">{product.description[lang]}</span>
			</div>

			<div className="NszGvUYJ">
				<span className="sm7N4JSo">
					{geoData.currency[lang]} {product.price.toFixed(2)}
				</span>
				<span className="sq1el3hZ">
					{geoData.currency[lang]} {product.discountedPrice.toFixed(2)}
				</span>
			</div>

			<div className="Y9BYuesY">
				<div className="S12YrPOx GvZOZ4mK" onClick={handleAddToCart}>
					<img className="ltgpmnpv" src="/assets/img/HPVU9JWG.svg" />
					<span className="PkYOSJqP">{translation.v6DApj6p[lang]}</span>
				</div>
			</div>

			{isLoading && !isMobile && <STXOf2f5 />}
		</div>
	);
};

export default W59X5NYD;
