import React, { useState } from "react";
import ZJHfBout from "./ZJHfBout";
import RTBp0NpV from "./RTBp0NpV";
import DIlLYniH from "./DIlLYniH";
import ERCBr7ei from "./ERCBr7ei";

const ZFE9QShe = ({
	geoData,
	restaurantData,
	restaurantMenu,
	translation,
	lang,
	geoCode,
	isMobile,
	isTablet,
	onRouteChange,
	reviewsData,
}) => {
	const [activeTab, setActiveTab] = useState("menu");

	const renderContent = () => {
		switch (activeTab) {
			case "menu":
				return (
					<ZJHfBout
						restaurantData={restaurantData}
						geoData={geoData}
						translation={translation}
						lang={lang}
						isMobile={isMobile}
						isTablet={isTablet}
					/>
				);
			case "reviews":
				return (
					<ERCBr7ei
						restaurantData={restaurantData}
						geoData={geoData}
						translation={translation}
						lang={lang}
						timezone={geoData.timezone}
						reviewsData={reviewsData}
					/>
				);
			case "info":
				return (
					<DIlLYniH
						restaurantData={restaurantData}
						geoData={geoData}
						translation={translation}
						lang={lang}
					/>
				);
			default:
				return null;
		}
	};

	return (
		<div className="gFS8VVb5">
			<div className="EwzAHC1c">
				<div className="fizYaa3q CBDSZHa5">
					<div
						className={`ZHcWH8k1 fV3xBBmH ${
							activeTab === "menu" ? "active" : ""
						}`}
						id={activeTab === "menu" ? "ldDWGbO9" : ""}
						onClick={() => setActiveTab("menu")}
					>
						<img className="VBscvKRg" src="/assets/img/muJuGiDY.svg" />
						<span className="tYfB00jd">{translation.WOQub84n[lang]}</span>
					</div>

					<div
						className={`ZHcWH8k1 TOT0z5J8 ${
							activeTab === "reviews" ? "active" : ""
						}`}
						id={activeTab === "reviews" ? "ldDWGbO9" : ""}
						onClick={() => setActiveTab("reviews")}
					>
						<img className="VBscvKRg" src="/assets/img/xAzVeJjw.svg" />
						<span className="tYfB00jd">{translation.vlcFTPcf[lang]}</span>
					</div>

					<div
						className={`ZHcWH8k1 fTMXk5fi ${
							activeTab === "info" ? "active" : ""
						}`}
						id={activeTab === "info" ? "ldDWGbO9" : ""}
						onClick={() => setActiveTab("info")}
					>
						<img className="VBscvKRg" src="/assets/img/m4AoiVwQ.svg" />
						<span className="tYfB00jd">{translation.gMUkdPg2[lang]}</span>
					</div>
				</div>
			</div>

			<div className="LcmRSd6D">
				{renderContent()}
				<RTBp0NpV
					geoData={geoData}
					restaurantMenu={restaurantMenu}
					translation={translation}
					lang={lang}
					geoCode={geoCode}
					isMobile={isMobile}
					isTablet={isTablet}
					onRouteChange={onRouteChange}
				/>
			</div>
		</div>
	);
};

export default ZFE9QShe;
