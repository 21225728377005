import React, { useEffect, useState } from "react";
import EoGOpVL1 from "./EoGOpVL1";
import STXOf2f5 from "./STXOf2f5";

const WznWrZnG = ({
	geoCode,
	geoData,
	isMobile,
	isTablet,
	translation,
	lang,
}) => {
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		const paramValue = params.get("WPZpwBzETNPFQrsBNqDseou1HHMpMIoE");

		if (paramValue === "JwtYYm3qer9CDZEHVSlnBd9snhtRMRAv") {
			setLoading(true);
			setTimeout(() => {
				alert(`${translation.pYLtWxLW[lang]}`);
				setLoading(false);
			}, 5000);
		} else if (paramValue === "dVE8yilAb1KHyB3curHoKb48ckq9UOrP") {
			setLoading(true);
			setTimeout(() => {
				alert(`${translation.VklB8R5t[lang]}`);
				setLoading(false);
			}, 5000);
		} else {
			setLoading(true);
			setTimeout(() => {
				setLoading(false);
			}, 5000);
		}
	}, []);

	return (
		<div>
			{loading && <STXOf2f5 />}

			<EoGOpVL1
				geoData={geoData}
				translation={translation}
				lang={lang}
				geoCode={geoCode}
				isMobile={isMobile}
				isTablet={isTablet}
			/>
		</div>
	);
};

export default WznWrZnG;
