import React, { forwardRef } from "react";
import U1ZLEl7H from "./U1ZLEl7H";
import VXYaWlDV from "./VXYaWlDV";
import Re5ycFbb from "./Re5ycFbb";

const Z8DCcqVk = forwardRef(
	(
		{
			geoCode,
			geoData,
			isMobile,
			isTablet,
			translation,
			lang,
			paymentMethods,
			selectedPaymentMethod,
			onSelectPaymentMethod,
		},
		ref
	) => {
		return (
			<div className="n5y0E5d9">
				{geoData.paymentMethods
					.filter((paymentMethod) => paymentMethod.status === "enabled")
					.map((paymentMethod) => {
						const methodInfo = paymentMethods.find(
							(method) => method.id === paymentMethod.id
						);

						if (!methodInfo) return null;

						switch (methodInfo.id) {
							case "qhfJmnbotAVnEOCtDx4czfCowB1bTlYy":
								return (
									<U1ZLEl7H
										key={methodInfo.id}
										lang={lang}
										translation={translation}
										paymentMethod={methodInfo}
										selected={selectedPaymentMethod === methodInfo.id}
										onSelect={() => onSelectPaymentMethod(methodInfo.id)}
										ref={ref}
									/>
								);
							case "pvWYUKLFgYkK6uXdBMGPeRbAGIVwPl4w":
								return (
									<VXYaWlDV
										key={methodInfo.id}
										lang={lang}
										translation={translation}
										paymentMethod={methodInfo}
										selected={selectedPaymentMethod === methodInfo.id}
										onSelect={() => onSelectPaymentMethod(methodInfo.id)}
									/>
								);
							case "LnzMYikHZKPrn0p5SotFvCtGRCmRZMnT":
								return (
									<Re5ycFbb
										key={methodInfo.id}
										lang={lang}
										translation={translation}
										paymentMethod={methodInfo}
										selected={selectedPaymentMethod === methodInfo.id}
										onSelect={() => onSelectPaymentMethod(methodInfo.id)}
									/>
								);
							default:
								return null;
						}
					})}
			</div>
		);
	}
);

export default Z8DCcqVk;
