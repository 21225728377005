import React from "react";

const BiQefJHK = ({
	geoData,
	geoCode,
	translation,
	lang,
	onRouteChange,
	restaurantData,
	cart,
	paymentMethods,
	selectedPaymentMethod,
	handlePaymentAction,
}) => {
	const getTotalPrice = () => {
		return cart.items.reduce((total, item) => {
			const product = restaurantData.menu.products.find(
				(p) => p.id === item.id
			);
			return total + (product ? product.price * item.quantity : 0);
		}, 0);
	};

	const getTotalDiscountedPrice = () => {
		return cart.items.reduce((total, item) => {
			const product = restaurantData.menu.products.find(
				(p) => p.id === item.id
			);
			return total + (product ? product.discountedPrice * item.quantity : 0);
		}, 0);
	};

	const totalPrice = getTotalPrice();
	const totalDiscountedPrice = getTotalDiscountedPrice();
	const deliveryFee = restaurantData.deliveryFee || 0;
	const serviceFee = restaurantData.serviceFee || 0;
	const finalPrice = totalDiscountedPrice + serviceFee;

	const selectedMethod = paymentMethods.find(
		(method) => method.id === selectedPaymentMethod
	);

	return (
		<div className="wS5T3JBE">
			<div className="h9wvH0ZX">
				<div className="ytblgdua">
					<span className="aJGHcWIB eSaeV12L pph0IAM4">
						{translation.jftlTfmL[lang]}
					</span>
				</div>
				<span className="l4jpK1Vf pph0IAM4 pph0IAM4">
					{geoData.currency[lang]} {totalPrice.toFixed(2)}
				</span>
			</div>

			<div className="h9wvH0ZX">
				<div className="ytblgdua">
					<span className="aJGHcWIB eSaeV12L K4m7TUzP">
						{translation.E7JshcXg[lang]}
					</span>
				</div>
				<span className="l4jpK1Vf eSaeV12L K4m7TUzP">
					- {geoData.currency[lang]}{" "}
					{(totalPrice - totalDiscountedPrice).toFixed(2)}
				</span>
			</div>

			<div className="h9wvH0ZX">
				<div className="ytblgdua">
					<span className="aJGHcWIB eSaeV12L pph0IAM4">
						{translation.OeA482zx[lang]}
					</span>
					<div className="pRjq6JTU">
						<img className="S7Bs6UVT" src="/assets/img/XW0gTOVA.svg" />
					</div>
				</div>
				<span className="l4jpK1Vf eSaeV12L Fi23N6fG e1nt1aIX">
					{geoData.currency[lang]} {deliveryFee.toFixed(2)}
				</span>
			</div>

			<div className="h9wvH0ZX">
				<div className="ytblgdua">
					<span className="aJGHcWIB eSaeV12 pph0IAM4">
						{translation.uByJZWrM[lang]}
					</span>
					<div className="pRjq6JTU">
						<img className="S7Bs6UVT" src="/assets/img/XW0gTOVA.svg" />
					</div>
				</div>
				<span className="l4jpK1Vf eSaeV12L pph0IAM4">
					{geoData.currency[lang]} {serviceFee.toFixed(2)}
				</span>
			</div>

			<div className="h9wvH0ZX">
				<div className="ytblgdua">
					<span className="aJGHcWIB eSaeV12L NLOG9Krj">
						{translation.KaXfAOBo[lang]}
					</span>
				</div>
				<span className="l4jpK1Vf eSaeV12L NLOG9Krj">
					{geoData.currency[lang]} {finalPrice.toFixed(2)}
				</span>
			</div>

			<div className="h9wvH0ZX">
				<div className="ytblgdua">
					<span className="aJGHcWIB eSaeV12L pph0IAM4">
						{selectedMethod.payBy[lang]}
					</span>
				</div>
				<span className="l4jpK1Vf eSaeV12L pph0IAM4">
					{geoData.currency[lang]} {finalPrice.toFixed(2)}
				</span>
			</div>

			<div className="KnCW3pvj CEzUuHLn" onClick={handlePaymentAction}>
				<span className="tmt1Bw0C">{translation.mbEbTFaa[lang]}</span>
			</div>
		</div>
	);
};

export default BiQefJHK;
