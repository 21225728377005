import React from "react";

const X6Igz1da = ({}) => {
	return (
		<div className="w1awQTt4">
			<img className="aum1sZ3u" src="/assets/img/w4D5oVuB.svg"></img>
		</div>
	);
};

export default X6Igz1da;
