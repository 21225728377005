import React from "react";

const Ktex1iGy = ({ rate, translation, lang }) => {
	const ratings = [
		{
			rate: 2,
			status: `${translation.uRKny2lN[lang]}`,
			path: "/assets/img/nc6SavKR.svg",
		},
		{
			rate: 3,
			status: `${translation.aOYG4eZn[lang]}`,
			path: "/assets/img/iS5AijiM.svg",
		},
		{
			rate: 4,
			status: `${translation.WJtz3jeF[lang]}`,
			path: "/assets/img/uQ16XSyA.svg",
		},
		{
			rate: 5,
			status: `${translation.QcAhFOKr[lang]}`,
			path: "/assets/img/vsjLE1GE.svg",
		},
	];

	const currentRating =
		ratings.find((rating) => rating.rate === rate) || ratings[0];

	return (
		<div className="tjrFKvxY">
			<div
				className="oii3URtv"
				style={{ backgroundImage: `url(${currentRating.path})` }}
			></div>
			<span className="Sg0W0Owu">{currentRating.status}</span>
		</div>
	);
};

export default Ktex1iGy;
