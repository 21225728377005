import React, { useEffect, useState } from "react";
import Umpub6Yk from "./Umpub6Yk";
import X6Igz1da from "./X6Igz1da";

const ERCBr7ei = ({
	restaurantData,
	geoData,
	translation,
	lang,
	reviewsData,
}) => {
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const timer = setTimeout(() => {
			setLoading(false);
		}, 1000);

		return () => clearTimeout(timer);
	}, []);

	if (loading) {
		return <X6Igz1da />;
	}

	return (
		<Umpub6Yk
			restaurantData={restaurantData}
			translation={translation}
			lang={lang}
			timezone={geoData.timezone}
			reviewsData={reviewsData}
		/>
	);
};

export default ERCBr7ei;
