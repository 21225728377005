import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import UkwLvfjq from "./UkwLvfjq";
import HMol3h2U from "./HMol3h2U";
import ZFE9QShe from "./ZFE9QShe";
import JeteE9e8 from "./JeteE9e8";
import MwawnQAy from "./MwawnQAy";
import { useCart } from "../contexts/CaRTCxqNL";

const BwyE18DL = ({
	geoCode,
	geoData,
	translation,
	lang,
	isMobile,
	isTablet,
	onRouteChange,
	paymentMethods,
}) => {
	const { restaurantId } = useParams();
	const [restaurantData, setRestaurantData] = useState(null);
	const [reviewsData, setReviewsData] = useState(null);
	const [showMwawnQAy, setShowMwawnQAy] = useState(false);
	const { cart } = useCart();
	const navigate = useNavigate();

	const handleShowMwawnQAy = () => setShowMwawnQAy(true);
	const handleCloseMwawnQAy = () => setShowMwawnQAy(false);

	useEffect(() => {
		if (!geoData || !restaurantId) return;

		const fetchRestaurantData = async () => {
			try {
				const RestaurantDataResponse = await fetch(
					`/restaurant/data/${restaurantId}.json`
				);

				const ReviewsDataResponse = await fetch(
					`/restaurant/data/reviews/${restaurantId}.json`
				);

				if (!RestaurantDataResponse.ok && ReviewsDataResponse.ok) {
				}

				const restaurantData = await RestaurantDataResponse.json();
				const reviewsData = await ReviewsDataResponse.json();
				setRestaurantData(restaurantData);
				setReviewsData(reviewsData);
			} catch (error) {
				navigate(`/${lang}/${geoCode}/`);
			}
		};

		fetchRestaurantData();
	}, [restaurantId, geoData, lang, geoCode]);

	useEffect(() => {
		if (cart.items.length === 0) {
			setShowMwawnQAy(false);
		}
	}, [cart]);

	if (!geoData) {
		return null;
	}

	if (!restaurantData) {
		return null;
	}

	const restaurant = geoData.restaurants.find(
		(restaurant) => restaurant.id === restaurantData.id
	);

	document.title = `${restaurant.name[lang]} ${translation.wK7vUtOM[lang]} ${restaurantData.location[lang]} | ${translation.d1okXsBa[lang]}`;

	return (
		<div className="lo6VRrhT">
			<div className="z47ukWTS M1MAll7b">
				<HMol3h2U
					restaurantData={restaurantData}
					geoCode={geoCode}
					translation={translation}
					lang={lang}
					areas={geoData.areas}
					geoData={geoData}
					onRouteChange={onRouteChange}
				/>
				<UkwLvfjq
					restaurantData={restaurantData}
					geoData={geoData}
					translation={translation}
					lang={lang}
					paymentMethods={paymentMethods}
				/>
				<ZFE9QShe
					restaurantData={restaurantData}
					geoData={geoData}
					restaurantMenu={restaurantData.menu}
					translation={translation}
					lang={lang}
					geoCode={geoCode}
					isMobile={isMobile}
					isTablet={isTablet}
					onRouteChange={onRouteChange}
					reviewsData={reviewsData}
				/>

				<JeteE9e8
					geoData={geoData}
					translation={translation}
					lang={lang}
					geoCode={geoCode}
					isMobile={isMobile}
					isTablet={isTablet}
					onShowMwawnQAy={handleShowMwawnQAy}
				/>

				{showMwawnQAy && (
					<MwawnQAy
						translation={translation}
						lang={lang}
						geoData={geoData}
						geoCode={geoCode}
						isMobile={isMobile}
						isTablet={isTablet}
						onClose={handleCloseMwawnQAy}
						onRouteChange={onRouteChange}
					/>
				)}
			</div>
		</div>
	);
};

export default BwyE18DL;
