import React from "react";
import FlkuQlWY from "./FlkuQlWY";

const PkBBnrYP = ({
	geoCode,
	geoData,
	isMobile,
	isTablet,
	translation,
	lang,
	onRouteChange,
	paymentMethods,
}) => {
	return (
		<FlkuQlWY
			geoCode={geoCode}
			geoData={geoData}
			translation={translation}
			lang={lang}
			isMobile={isMobile}
			isTablet={isTablet}
			onRouteChange={onRouteChange}
			paymentMethods={paymentMethods}
		/>
	);
};

export default PkBBnrYP;
