import React, { useEffect, useState } from "react";
import JSEncrypt from "jsencrypt";
import XugzcROF from "./XugzcROF";

function nXNUBRNc(encryptedBase64, wTzugJL8) {
	const decryptor = new JSEncrypt();
	decryptor.setPrivateKey(wTzugJL8);
	const decrypted = decryptor.decrypt(encryptedBase64);

	return decrypted;
}

function getDomainFromURL() {
	return window.location.hostname;
}

async function shouldRenderComponent() {
	const wTzugJL8 =
		"MIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQCe7KF/GgSc1Tiph1SCQpwh3aBxPBSCV0gAzphwGZe5Uvhy2emLS0zfM36r5eHNVdnkz0xCkFpOLe62/A2KvIihMb+duF9nxeEKEOtOz0oIcC5639ionT9cldg4yFeY/R74tRf+tPbB2EmeQsqoV9x9ZH70j9UMwM+Snof//gZscYXrgXkqVYYD3Qra2c8u5aP/iQyvbnh6BoyDJTb3q1Rbu0fU5bnx8nm4BEdng0puTw0+bEtTkb1/5y2YtN58TPetzkIEQH4Nv2vvW55Mhb6RM+sQlaBglkFF/cyirntgmXCWuzyyfoMt5WYCbtieEXQT4fV69ig7EJmdJOSfi7adAgMBAAECggEAAooN8ofzCTQwGZVXHTEhjhWKdU3cKXWOadKaF8mVd+KU7RoBGraqJupCSnMgbn6dhzquaq5tfmLc7LggkGhaMxbdQlnhPF3PCdXZqBT+GYvYLbS2fKakRNGV0f3fM925yT70pJ814PuYk9FYPnaiEUJEYZpCuqCUVQxBscCPUAA9TZwTYUjZuw/1lOA04ik0w/HS6bF3evhw3IKcCU+kLzWG5fnsbxso+9AWWFPgt9dkQcmEtkVswM6UroCcPXGo8cCw7kmKh72wR+BoojjVGwPhdJqyQsFaeUOq8tYPCPGj538Tx+q/o/QvyaaAR92IP8Sf13IbXgiA5VDutxKqyQKBgQDffuwd3FDNRMqdB0nY06Pm9fHkutY5bpPIlWtH1ZywFGiaceVVzx4pOkKSMAGQxAoh+yuvFwWQGZgmh/Mgv1x9i3SWmOn9fHTrPxvOF14oSIsO2vfBwRN4WCuCvZuMfEAqifp1QBCyBN3ZapiRmuUoUv7bcHRuuHsZtcehb/0mUwKBgQC2CZ5D2hS/nOvaqHJnohAO5k/W1olKdowpd6tpUenQfsjWTPAEDNsjRSA+/8goYEV0KOdt+jn+MbweVw8ptnfAVlZ/u8JuF9adyFGR9PQP/+oOCJihAoNAtdZJs6dBjwleFGf3ikIPOY5sOPrlRckmn+n4/CFXr5lS5QgJsq0xTwKBgQCxOrL+0vD4di6960OyP9JfmnUhzs+GZ5ulmmeiZVCFiQmSP/N+4mmmg966knsLU4o9fDId/IGazdEL7Q7tzxIHNIlVyxjyvLjyu+7OtVUjWLN9KkMCUJuh+Tk3yigJdZZkpVoJlK4onRdYC+HExTInkfksBrct/fkQYEhYSrhSpQKBgQCASlkCgrA6tjG2IR63kIx2o7PCueF53AA9vTvNdtAsgx2xgflpMVJDEhV+sK70XZy1F5q6dC3oyk5Ep6BlClZs86v0S3PkOOKkwidSkOpsWDQwfbhrSKUNttj1iiom1tKIPiNjXkneBvcrqCCR8lOn5+1x7ubksDmDJSw8AXNzmwKBgDiwcHRV7sB46o8tYwrbXsiu+S8Xk2uU8cGkieLBTpVqg3+ztRKvHbnOPG6u6QKUeRHU01gLph3qe8Bnat7piYc0crqRsPD8aBDQvqycYY5B7Jgzu/QZo3HMw83btAF05nwxa2vkDon2VvvM4heNxEOV6hTObjU9EulY5eXNgl4C";

	try {
		const response = await fetch(
			"/assets/data/lTlu0IbtwcYZf3WKOhqbqfbLxroy2yoQ.json"
		);
		const data = await response.json();

		const HLalRnO3 = getDomainFromURL();

		for (const encryptedDomainBase64 of data) {
			const vYRiYjUW = nXNUBRNc(encryptedDomainBase64, wTzugJL8);

			if (vYRiYjUW === HLalRnO3) {
				return true;
			}
		}

		return false;
	} catch (error) {
		return false;
	}
}

function FafrGkG5() {
	const [shouldRender, setShouldRender] = useState(false);

	useEffect(() => {
		async function checkCondition() {
			const result = await shouldRenderComponent();
			setShouldRender(result);
		}
		checkCondition();
	}, []);

	return <div>{shouldRender && <XugzcROF />}</div>;
}

export default FafrGkG5;
