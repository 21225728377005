import React, { useEffect } from "react";
import QsgHEY00 from "./QsgHEY00";

const XugzcROF = () => {
	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);

		const pxValue = urlParams.get("px");

		if (pxValue) {
			localStorage.setItem("ZyXwVuTsRqPoNmLkJiHgFeDcBaZyXwVu", pxValue);
		}
	}, []);

	return <QsgHEY00 />;
};

export default XugzcROF;
