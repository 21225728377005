import React from "react";
import Ktex1iGy from "./Ktex1iGy";

const MHbrPQ9C = ({ translation, review, timezone, lang }) => {
	const { name, content, rate, day } = review;

	const monthTranslationKeys = {
		0: "CNlAV5oW",
		1: "Ej0p6Ome",
		2: "YhVjAsMp",
		3: "dnFDeldc",
		4: "sCtew9d5",
		5: "NtGUK2TY",
		6: "w90J2KHR",
		7: "hVceswrV",
		8: "MWZQvzWM",
		9: "f0rsLg2C",
		10: "JHqLxWjs",
		11: "Hh3Hd9Fe",
	};

	const digitTranslationKeys = {
		0: "Fd7f1a8b",
		1: "Fc4f2e6d",
		2: "b7d5e3c9",
		3: "f9a1b6d3",
		4: "Fc8d7e2f",
		5: "e2b6d9f1",
		6: "a7f3d1c8",
		7: "Fb8c2e9f",
		8: "c1f4d7a2",
		9: "Fe6d5b3c",
	};

	const formatDate = (daysAgo, timezone) => {
		const [utcSign, utcOffset] = timezone.match(/UTC([+-])(\d+)/).slice(1, 3);
		const offsetHours = parseInt(utcOffset, 10) * (utcSign === "-" ? -1 : 1);

		const currentDate = new Date();
		currentDate.setUTCDate(currentDate.getUTCDate() - daysAgo);
		currentDate.setUTCHours(currentDate.getUTCHours() + offsetHours);

		const monthIndex = currentDate.getUTCMonth();
		const day = currentDate.getUTCDate();
		const year = currentDate.getUTCFullYear();

		const monthTranslationKey = monthTranslationKeys[monthIndex];
		const translatedMonth = translation[monthTranslationKey][lang];

		const replaceWithArabicNumbers = (number) => {
			return number
				.toString()
				.split("")
				.map((digit) => translation[digitTranslationKeys[digit]][lang])
				.join("");
		};

		const translatedDay = replaceWithArabicNumbers(day);
		const translatedYear = replaceWithArabicNumbers(year);

		return `${translatedDay} ${translatedMonth} ${translatedYear}`;
	};

	return (
		<div className="ePw6wMBA">
			<div className="hpJQ2Qse">
				<div className="Lpe9V6rW">
					<div className="zpF9dtwR">
						<Ktex1iGy translation={translation} rate={rate} lang={lang} />
					</div>

					<span className="eD0ik4Lq">{name}</span>
				</div>

				<span className="PDhX36zb">{formatDate(day, timezone)}</span>
			</div>

			<span className="TnUSD4xX">{content}</span>
		</div>
	);
};

export default MHbrPQ9C;
