import React from "react";
import LvLjF4lF from "./LvLjF4lF";

const TWxgJJ3P = ({
	geoData,
	translation,
	lang,
	geoCode,
	isMobile,
	onRouteChange,
}) => {
	const currentYear = new Date().getFullYear();

	const handleButtonClick = () => {
		onRouteChange(`/${lang}/${geoCode}/`);
	};
	return (
		<div className="ne5QPEb9">
			<div className="sIeWWQJH">
				<div className="u9eYglhB">
					<div className="pQgEGDVa">
						<span className="MQLdUZRS" onClick={handleButtonClick}>
							{translation.xmNumEbE[lang]}
						</span>
						<span className="MQLdUZRS" onClick={handleButtonClick}>
							{translation.QmMcnluf[lang]}
						</span>
						<span className="MQLdUZRS" onClick={handleButtonClick}>
							{translation.AjCs5vIo[lang]}
						</span>
						<span className="MQLdUZRS" onClick={handleButtonClick}>
							{translation.zOakhkNt[lang]}
						</span>
						<span className="MQLdUZRS" onClick={handleButtonClick}>
							{translation.eU1bEUQ0[lang]}
						</span>
						<span className="MQLdUZRS" onClick={handleButtonClick}>
							{translation.D6j7SiWL[lang]}
						</span>
					</div>

					<div className="uQRRQlqx">
						<LvLjF4lF
							columnIds={geoData.footer.restaurants}
							columnData={geoData.restaurants}
							lang={lang}
							geoCode={geoCode}
							headerText={translation.ZHcydKCS}
							footerText={translation.cbzlpPqV}
							isMobile={isMobile}
							navigateParams={"restaurant"}
							onRouteChange={onRouteChange}
						/>
						<LvLjF4lF
							columnIds={geoData.footer.cuisines}
							columnData={geoData.cuisines}
							lang={lang}
							geoCode={geoCode}
							headerText={translation.h4Yutu4t}
							footerText={translation.B30S8RDt}
							isMobile={isMobile}
							navigateParams={"cuisine"}
							onRouteChange={onRouteChange}
						/>
						<LvLjF4lF
							columnIds={geoData.footer.areas}
							columnData={geoData.areas}
							lang={lang}
							geoCode={geoCode}
							headerText={translation.fCQ9TTVI}
							footerText={translation.UWi7aEvF}
							isMobile={isMobile}
							navigateParams={"area"}
							onRouteChange={onRouteChange}
						/>
						{geoCode === "ae" && (
							<LvLjF4lF
								columnIds={geoData.footer.cities}
								columnData={geoData.cities}
								lang={lang}
								geoCode={geoCode}
								headerText={translation.SzWRTFwp}
								footerText={translation.LAm7hY52}
								isMobile={isMobile}
								navigateParams={"city"}
								onRouteChange={onRouteChange}
							/>
						)}

						<div className="NJK64vIV">
							<span className="iWpiqyOz Z1ITjtsx">
								{translation.CnfEzLNY[lang]}
							</span>
							<div className="AJniCtFq">
								<a className="OPoaJAcp" href="https://www.facebook.com/Talabat">
									<img
										className="MNqkmnGk"
										src="/assets/img/GCERdMsu.svg"
									></img>
								</a>

								<a className="OPoaJAcp" href="https://twitter.com/Talabat">
									<img
										className="MNqkmnGk"
										src="/assets/img/RpGrLWUC.svg"
									></img>
								</a>

								<a className="OPoaJAcp" href="https://instagram.com/Talabat">
									<img
										className="MNqkmnGk"
										src="/assets/img/nR8ltoxr.svg"
									></img>
								</a>

								<a
									className="OPoaJAcp"
									href="https://www.linkedin.com/company/3604171"
								>
									<img
										className="MNqkmnGk"
										src="/assets/img/jRAhmr3b.svg"
									></img>
								</a>

								<a
									className="OPoaJAcp"
									href="https://www.youtube.com/TalabatGTC"
								>
									<img
										className="MNqkmnGk"
										src="/assets/img/WmaG7ONB.svg"
									></img>
								</a>
							</div>
						</div>
					</div>
				</div>

				<div className="htGWBKpw">
					<span className="cV5Ga4cp">{translation.iI7Ilnz8[lang]}</span>

					<span className="cV5Ga4cp">
						©<span className="cV5Ga4cp">{currentYear}</span>{" "}
						{translation.ILcMRURo[lang]}
					</span>
				</div>
			</div>
		</div>
	);
};

export default TWxgJJ3P;
