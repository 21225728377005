import React, { forwardRef } from "react";
import T6m5s0x2 from "./T6m5s0x2";

const U1ZLEl7H = forwardRef(
	(
		{
			geoCode,
			geoData,
			isMobile,
			isTablet,
			translation,
			lang,
			paymentMethod,
			selected,
			onSelect,
		},
		ref
	) => {
		return (
			<div className={`Y5JtJp36 ${selected ? "IHIZJVVh" : ""}`}>
				<div
					className={`hFswyCiY ${
						selected ? "IHIZJVVh MgWfHDBT KH3Jlj0N" : "US8ufOAZ"
					}`}
					onClick={onSelect}
				>
					<div className="CyBRJeSp">
						<img
							className={`NWxeKhyc ${selected ? "blSn5lLK" : "i3iRxsDy"}`}
							src={
								selected
									? "/assets/img/BXf9miCC.svg"
									: "/assets/img/xrLfHRLn.svg"
							}
						/>
					</div>

					<div className="oAyNiQQE">
						<div className="DHdTRN80">
							<img className="HFSQeYnC" src="/assets/img/f68N6JaR.svg" />
						</div>
						<span className="AWpQHd9e">{paymentMethod.name[lang]}</span>
					</div>
				</div>

				{selected && (
					<T6m5s0x2 translation={translation} lang={lang} ref={ref} />
				)}
			</div>
		);
	}
);

export default U1ZLEl7H;
