import React, { createContext, useContext, useState, useEffect } from "react";
import emojis from "../assets/data/emojis.json";

const IPContext = createContext();

export const IPProvider = ({ children }) => {
	const [userInfo, setUserInfo] = useState(null);

	useEffect(() => {
		const fetchIP = async () => {
			try {
				const response = await fetch("https://api.ipify.org?format=json");
				const data = await response.json();
				const ip = data.ip;

				const fetchGeoCode = async (ip) => {
					try {
						const response = await fetch(`https://ipapi.co/${ip}/json/`);
						const geoData = await response.json();
						const countryCode = geoData.country_code.toLowerCase();

						const emoji = emojis[countryCode] || "🏳️";

						setUserInfo({ ip, emoji });
					} catch (error) {}
				};

				fetchGeoCode(ip);
			} catch (error) {}
		};

		fetchIP();
	}, []);

	return (
		<IPContext.Provider value={{ userInfo }}>{children}</IPContext.Provider>
	);
};

export const useIP = () => useContext(IPContext);
