import React, { useState, useEffect } from "react";
import Ktex1iGy from "./Ktex1iGy";
import RdlKuRQz from "./RdlKuRQz";
import OYAImWDC from "./OYAImWDC";

const UkwLvfjq = ({
	restaurantData,
	geoData,
	translation,
	lang,
	paymentMethods,
}) => {
	const [isOpen, setIsOpen] = useState(false);

	const restaurant = geoData.restaurants.find(
		(restaurant) => restaurant.id === restaurantData.id
	);

	useEffect(() => {
		const checkIsOpen = () => {
			const timezoneOffset = parseInt(geoData.timezone.replace("UTC", ""), 10);

			const now = new Date();
			const utcHours = now.getUTCHours();
			const utcMinutes = now.getUTCMinutes();

			const localHours = (utcHours + timezoneOffset + 24) % 24;
			const localMinutes = utcMinutes;

			const [start, end] = restaurantData.workingHours.split("-");
			const [startHours, startMinutes] = start.split(":").map(Number);
			const [endHours, endMinutes] = end.split(":").map(Number);

			const isOpenNow =
				localHours > startHours ||
				(localHours === startHours && localMinutes >= startMinutes);
			const isClosedNow =
				localHours < endHours ||
				(localHours === endHours && localMinutes <= endMinutes);

			if (isOpenNow && isClosedNow) {
				setIsOpen(true);
			} else {
				setIsOpen(false);
			}
		};

		checkIsOpen();
	}, [geoData.timezone, restaurantData.workingHours]);

	const filteredCuisines = geoData.cuisines
		.filter((item) => restaurantData.cuisines.includes(item.id))
		.slice(0, 5);

	const cuisineNames = filteredCuisines
		.map((item) => item.name[lang])
		.join(", ");

	return (
		<div className="A2k7jox7">
			<div className="P6nRpmqP">
				<OYAImWDC img={restaurant.img} />

				<div className="ji5ntsiz">
					<div className="sf4ZnOFI">
						<div className="lfpMmP41">
							<span className="PwtyJbDR ezO42Hrz">{restaurant.name[lang]}</span>
							<span className="z66VnazC vjGp0kb5">
								{translation.pKw9Mfxk[lang]} {restaurantData.location[lang]},{" "}
								{geoData.name[lang]}
							</span>
						</div>

						<div className="KN1zttKh">
							{isOpen ? (
								<span className="ddoJhsXg oQw2dWxa">
									{translation.qHX0R1iI[lang]}
								</span>
							) : (
								<span className="ddoJhsXg DBJ6YkEV">
									{translation.RSqBDeFz[lang]}
								</span>
							)}
						</div>
					</div>

					<div className="jddoz8nq">
						<div className="ZW8i3qf8">
							<div className="Y0iMnMCY">
								<span className="zWvqsJtb CFUwEqNj">{cuisineNames}</span>

								<Ktex1iGy
									rate={restaurantData.rate}
									translation={translation}
									lang={lang}
								/>
							</div>

							<div className="eYqtgIz3">
								<span className="IXEFib4Z DjPwFS5X">
									{translation.FqNKuctW[lang]} {geoData.currency[lang]}{" "}
									{restaurantData.minOrder.toFixed(2)}
								</span>

								<RdlKuRQz
									geoData={geoData}
									translation={translation}
									paymentMethods={paymentMethods}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="Gyka6sdu">
				<img className="F0gldIDp" src="/assets/img/pUKDGn3u.svg"></img>
				<span className="yH4DN0LO">{translation.cWLpQJwM[lang]}</span>
			</div>
		</div>
	);
};

export default UkwLvfjq;
