import React from "react";

const HMol3h2U = ({
	geoData,
	geoCode,
	restaurantData,
	translation,
	lang,
	areas,
	onRouteChange,
}) => {
	const handleRedirectHome = () => {
		onRouteChange(`/${lang}/${geoCode}/`);
	};

	const area = areas.find((area) => area.id === restaurantData.area);

	const restaurant = geoData.restaurants.find(
		(restaurant) => restaurant.id === restaurantData.id
	);

	const handleRedirectArea = () => {
		onRouteChange(`/${lang}/${geoCode}/area/${area.id}`);
	};

	return (
		<div className="qxykgFfn DYOwABBe">
			<div className="FRe8PduV">
				<span className="LcX0dznF" onClick={handleRedirectHome}>
					{translation.K971GEkB[lang]}
				</span>

				<img className="hpXuACSR" src="/assets/img/w04C5hx9.svg"></img>

				<span className="LcX0dznF" onClick={handleRedirectArea}>
					{area.name[lang]}
				</span>

				<img className="hpXuACSR" src="/assets/img/w04C5hx9.svg"></img>

				<span className="MSnZ0Nyj">{restaurant.name[lang]}</span>
			</div>
		</div>
	);
};

export default HMol3h2U;
