import React from "react";
import VYasDpoM from "./VYasDpoM";

const RTBp0NpV = ({
	geoCode,
	geoData,
	isMobile,
	isTablet,
	translation,
	lang,
	onRouteChange,
}) => {
	const shouldDisplay = !isTablet && !isMobile;

	if (!shouldDisplay) return null;
	return (
		<VYasDpoM
			geoData={geoData}
			translation={translation}
			lang={lang}
			geoCode={geoCode}
			isMobile={isMobile}
			isTablet={isTablet}
			onRouteChange={onRouteChange}
		/>
	);
};

export default RTBp0NpV;
