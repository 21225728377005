import React from "react";

const AJUHtCMt = ({
	geoData,
	geoCode,
	translation,
	lang,
	onRouteChange,
	restaurantData,
}) => {
	return (
		<div className="r9tdXEil">
			<div className="QO9IKp6D">
				<span className="ljPn3lNI">{translation.Oev3Etnd[lang]}</span>

				<span className="TrNPMabA" onClick={null}>
					{translation.FQZzKma1[lang]}
				</span>
			</div>
			<div className="I7VK9sTD">
				<span className="QRV7Ym0b">{translation.LnWArxd2[lang]}</span>
			</div>
		</div>
	);
};

export default AJUHtCMt;
