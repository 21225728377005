import React, { createContext, useContext, useState, useEffect } from "react";

const CART_STORAGE_KEY = "WGspIOiAW4AEp0ubKwMif3a1G9RKjeqi";

const CartContext = createContext();

export const useCart = () => useContext(CartContext);

export const CartProvider = ({ children }) => {
	const [cart, setCart] = useState(() => {
		const savedCart = localStorage.getItem(CART_STORAGE_KEY);
		return savedCart ? JSON.parse(savedCart) : { id: null, items: [] };
	});

	const [currentRestaurantData, setCurrentRestaurantData] = useState(null);

	useEffect(() => {
		localStorage.setItem(CART_STORAGE_KEY, JSON.stringify(cart));
	}, [cart]);

	const fetchCurrentRestaurantData = async (restaurantId) => {
		try {
			const response = await fetch(`/restaurant/data/${restaurantId}.json`);
			if (!response.ok) {
			}
			const data = await response.json();
			setCurrentRestaurantData(data);
		} catch (error) {}
	};

	useEffect(() => {
		if (cart.id) {
			fetchCurrentRestaurantData(cart.id);
		} else {
			setCurrentRestaurantData(null);
		}
	}, [cart.id]);

	const addItemToCart = (itemId, restaurantId) => {
		if (cart.id && cart.id !== restaurantId) {
			clearCart();
			setCart({ id: restaurantId, items: [{ id: itemId, quantity: 1 }] });
		} else if (!cart.id) {
			setCart({ id: restaurantId, items: [{ id: itemId, quantity: 1 }] });
		} else {
			setCart((prevCart) => {
				const existingItem = prevCart.items.find((item) => item.id === itemId);
				if (existingItem && existingItem.quantity >= 9) {
					return prevCart;
				}
				return {
					...prevCart,
					items: existingItem
						? prevCart.items.map((item) =>
								item.id === itemId
									? { ...item, quantity: item.quantity + 1 }
									: item
						  )
						: [...prevCart.items, { id: itemId, quantity: 1 }],
				};
			});
		}
	};

	const removeItemFromCart = (itemId) => {
		setCart((prevCart) => {
			const itemToRemove = prevCart.items.find((item) => item.id === itemId);
			if (itemToRemove && itemToRemove.quantity > 1) {
				return {
					...prevCart,
					items: prevCart.items.map((item) =>
						item.id === itemId ? { ...item, quantity: item.quantity - 1 } : item
					),
				};
			} else {
				return {
					...prevCart,
					items: prevCart.items.filter((item) => item.id !== itemId),
				};
			}
		});
	};

	const removeAllItemsById = (itemId) => {
		setCart((prevCart) => ({
			...prevCart,
			items: prevCart.items.filter((item) => item.id !== itemId),
		}));
	};

	const clearCart = () => {
		setCart({ id: null, items: [] });
		setCurrentRestaurantData(null);
	};

	return (
		<CartContext.Provider
			value={{
				cart,
				currentRestaurantData,
				addItemToCart,
				removeItemFromCart,
				removeAllItemsById,
				clearCart,
			}}
		>
			{children}
		</CartContext.Provider>
	);
};
