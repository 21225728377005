import React from "react";
import { useCart } from "../contexts/CaRTCxqNL";

const MY52L4c6 = () => {
	const { cart } = useCart();

	const totalItems = cart.items.reduce(
		(total, item) => total + item.quantity,
		0
	);

	return (
		<div className="JjyarlsG">
			<div
				className="hBPJyizP"
				style={{ backgroundImage: `url('/assets/img/EhrkRIU1.svg')` }}
			></div>
			<span className="uB0zPj7L">{totalItems}</span>{" "}
		</div>
	);
};

export default MY52L4c6;
