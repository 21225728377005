import React from "react";

const STXOf2f5 = ({}) => {
	return (
		<div className="aucCroPn">
			<div className="yC2jQoN7">
				<div className="ScVIyPBi">
					<img className="vXYA2Pji" src="/assets/img/w4D5oVuB.svg"></img>
				</div>
			</div>
		</div>
	);
};

export default STXOf2f5;
