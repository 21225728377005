import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Ye2IDGUn = ({ geoCode, lang }) => {
	const navigate = useNavigate();
	useEffect(() => {
		navigate(`/${lang}/${geoCode}/offers/`);
	}, [geoCode, lang]);

	return <div></div>;
};

export default Ye2IDGUn;
