import React, { useState } from "react";

const TGzLYVAu = ({}) => {
	const [isActive, setIsActive] = useState(false);

	const handleClick = () => {
		setIsActive((prevState) => !prevState);
	};

	return (
		<div className="TZtmZWSF" onClick={handleClick}>
			<img className="VMfgttiP" src="/assets/img/Hq0FrMd4.svg"></img>
			<img
				className={`VMfgttiP ${isActive ? "bjuujARW" : "ywEN3AGl"}`}
				src="/assets/img/xoXBKcbT.svg"
			></img>
		</div>
	);
};

export default TGzLYVAu;
