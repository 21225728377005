import React from "react";
import GiMvkzcg from "./GiMvkzcg";

const VXYaWlDV = ({
	geoCode,
	geoData,
	isMobile,
	isTablet,
	translation,
	lang,
	paymentMethod,
	selected,
	onSelect,
}) => {
	return (
		<div className={`Y5JtJp36 ${selected ? "IHIZJVVh" : ""}`}>
			<div
				className={`hFswyCiY ${
					selected ? "IHIZJVVh MgWfHDBT KH3Jlj0N" : "US8ufOAZ"
				}`}
				onClick={onSelect}
			>
				<div className="CyBRJeSp" onClick={onSelect}>
					<img
						className={`NWxeKhyc ${selected ? "blSn5lLK" : "i3iRxsDy"}`}
						src={
							selected ? "/assets/img/BXf9miCC.svg" : "/assets/img/xrLfHRLn.svg"
						}
					/>
				</div>

				<div className="oAyNiQQE">
					<div className="DHdTRN80">
						<img className="HFSQeYnC" src="/assets/img/mxX3TO1f.png"></img>
					</div>
					<span className="AWpQHd9e">{paymentMethod.name[lang]}</span>
				</div>
			</div>

			{selected && <GiMvkzcg translation={translation} lang={lang} />}
		</div>
	);
};

export default VXYaWlDV;
