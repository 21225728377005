import React, { useEffect, useRef, useState } from "react";

const useIntersectionObserver = (elements, callback, options) => {
	useEffect(() => {
		const observer = new IntersectionObserver(callback, options);

		elements.forEach((element) => {
			if (element.current) observer.observe(element.current);
		});

		return () => {
			elements.forEach((element) => {
				if (element.current) observer.unobserve(element.current);
			});
		};
	}, [elements, callback, options]);
};

const SaW5FYZh = ({ product }) => {
	const [isVisible, setIsVisible] = useState(false);
	const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 768);
	const firstImageRef = useRef(null);
	const secondImageRef = useRef(null);

	const handleResize = () => setIsWideScreen(window.innerWidth > 768);

	const handleIntersection = (entries) => {
		entries.forEach((entry) => {
			if (entry.isIntersecting) {
				setIsVisible(true);
			}
		});
	};

	useIntersectionObserver([firstImageRef, secondImageRef], handleIntersection, {
		threshold: 0.1,
	});

	useEffect(() => {
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return (
		<div className="FGvLYxMU">
			<div
				className="FdqvMMVq"
				style={{
					backgroundImage: `url( ${
						isVisible ? "" : "/assets/img/eifDBjMW.svg"
					})`,
				}}
			>
				<div
					ref={firstImageRef}
					className={`ANzxpbac RjaxzHG6 jfnRxL8O Shf3kcjy ${
						isVisible ? "q6BkvhfL" : "AXDu8WkP"
					}`}
					style={{ backgroundImage: `url(${product.image})` }}
					loading="lazy"
				></div>
			</div>

			{isWideScreen && (
				<div className="yqxGG3G6">
					<div className="h69nullW">
						<div
							className="v0xtonPH PVR1h2Tw"
							style={{
								backgroundImage: `url( ${
									isVisible ? "" : "/assets/img/eifDBjMW.svg"
								})`,
							}}
						>
							<div
								ref={secondImageRef}
								className={`v0xtonPH jIWqs4gr YD0FXjkD fpBabDpu ${
									isVisible ? "q6BkvhfL" : "AXDu8WkP"
								}`}
								loading="lazy"
								style={{ backgroundImage: `url(${product.image})` }}
							></div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default SaW5FYZh;
