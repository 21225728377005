import React, { useEffect, useRef, useState } from "react";

const useIntersectionObserver = (elements, callback, options) => {
	useEffect(() => {
		const observer = new IntersectionObserver(callback, options);

		elements.forEach((element) => {
			if (element.current) observer.observe(element.current);
		});

		return () => {
			elements.forEach((element) => {
				if (element.current) observer.unobserve(element.current);
			});
		};
	}, [elements, callback, options]);
};

const P83xQfm8 = ({ lang, restaurants, onRouteChange, geoCode }) => {
	const [visibleImages, setVisibleImages] = useState({});
	const imageRefs = useRef(restaurants.map(() => React.createRef()));

	const handleIntersection = (entries) => {
		const newVisibleImages = { ...visibleImages };
		entries.forEach((entry) => {
			const index = entry.target.dataset.index;
			if (entry.isIntersecting) {
				newVisibleImages[index] = true;
			}
		});
		setVisibleImages(newVisibleImages);
	};

	useIntersectionObserver(imageRefs.current, handleIntersection, {
		threshold: 0.1,
	});

	return (
		<div className="L7qOJLUe">
			{restaurants.map((restaurant, index) => (
				<div
					key={restaurant.id}
					className="AlI0yCLM"
					onClick={() =>
						onRouteChange(`/${lang}/${geoCode}/restaurant/${restaurant.id}`)
					}
				>
					<div className="olYLSRFx">
						<div className="zqDxNEQz">
							<div
								className=" PpS6vfVC"
								style={{
									backgroundImage: `url( ${
										visibleImages[index] ? "" : "/assets/img/eifDBjMW.svg"
									})`,
								}}
							>
								<div
									ref={imageRefs.current[index]}
									data-index={index}
									className={`ANzxpbac PpS6vfVC ${
										visibleImages[index] ? "q6BkvhfL" : "AXDu8WkP"
									}`}
									style={{ backgroundImage: `url(${restaurant.img})` }}
									loading="lazy"
								></div>
							</div>
						</div>
						<div className="bwUnnSR5">
							<span className="SpYSDHvo">{restaurant.name[lang]}</span>
						</div>
					</div>
				</div>
			))}
		</div>
	);
};

export default P83xQfm8;
