import React from "react";

const D99oMFAB = ({ translation, lang }) => {
	return (
		<div className="IDMU3SsX">
			<div className="WkxxIyG7">
				<img className="n7nbFddi" src="/assets/img/Hn53zRCq.svg"></img>
			</div>
			<span className="Bp9vXwYf">{translation.zoXXYdBq[lang]}</span>
		</div>
	);
};

export default D99oMFAB;
