import React from "react";
import Ktex1iGy from "./Ktex1iGy";
import DxkaSk2I from "./DxkaSk2I";

const Umpub6Yk = ({
	translation,
	restaurantData,
	timezone,
	lang,
	reviewsData,
}) => {
	return (
		<div className="widRFfEZ">
			<div className="XrrnY00B">
				<span className="UB8iPBk5">
					({reviewsData.length}) {translation.pPtObU3W[lang]}
				</span>
				<div className="FToPEefy">
					<div className="YALgyXv3">
						<div className="h4l8Ty3F sf4ZE1Xp">
							<Ktex1iGy
								rate={restaurantData.orderPackagingRate}
								translation={translation}
								lang={lang}
							/>
							<span className="nncdHEV5">{translation.ysgcUdhj[lang]}</span>
						</div>

						<div className="h4l8Ty3F hMyOvPvf">
							<Ktex1iGy
								rate={restaurantData.ValueForMoneyRate}
								translation={translation}
								lang={lang}
							/>
							<span className="nncdHEV5">{translation.xThbzz9n[lang]}</span>
						</div>
					</div>

					<div className="YALgyXv3">
						<div className="h4l8Ty3F sf4ZE1Xp">
							<Ktex1iGy
								rate={restaurantData.deliveryTimeRate}
								translation={translation}
								lang={lang}
							/>
							<span className="nncdHEV5">{translation.cRyM5yYy[lang]}</span>
						</div>

						<div className="h4l8Ty3F">
							<Ktex1iGy
								rate={restaurantData.qualityOfFoodRate}
								translation={translation}
								lang={lang}
							/>
							<span className="nncdHEV5">{translation.ab4HZ865[lang]}</span>
						</div>
					</div>
				</div>
			</div>

			<DxkaSk2I
				restaurantData={restaurantData}
				translation={translation}
				timezone={timezone}
				lang={lang}
				reviewsData={reviewsData}
			/>
		</div>
	);
};

export default Umpub6Yk;
