import React from "react";
import D99oMFAB from "./D99oMFAB";

const VYasDpoM = ({
	geoData,
	translation,
	lang,
	geoCode,
	isMobile,
	isTablet,
	onRouteChange,
}) => {
	return (
		<div className="U9HZaekh">
			<div className="ZLLZpBbz">
				<div className="v3Zk8f8m">
					<span className="OPI0bgdq">{translation.cZ1hl2jd[lang]}</span>
				</div>
				<D99oMFAB
					translation={translation}
					lang={lang}
					geoData={geoData}
					geoCode={geoCode}
					isMobile={isMobile}
					isTablet={isTablet}
					onRouteChange={onRouteChange}
				/>
			</div>
		</div>
	);
};

export default VYasDpoM;
