import React, { useState, useEffect } from "react";
import EXyVyy7R from "./EXyVyy7R";

function QsgHEY00() {
	const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
	const [isTablet, setIsTablet] = useState(
		window.innerWidth >= 768 && window.innerWidth <= 1024
	);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 768);
			setIsTablet(window.innerWidth >= 768 && window.innerWidth <= 1024);
		};

		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return <EXyVyy7R isMobile={isMobile} isTablet={isTablet} />;
}

export default QsgHEY00;
