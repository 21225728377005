import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import VRF6GlW0 from "./VRF6GlW0";
import TWxgJJ3P from "./TWxgJJ3P";
import STXOf2f5 from "./STXOf2f5";
import BwyE18DL from "./BwyE18DL";
import VDyn8ZsI from "./VDyn8ZsI";
import PkBBnrYP from "./PkBBnrYP";
import A7TJHBY9 from "./A7TJHBY9";
import Ye2IDGUn from "./Ye2IDGUn";

import paymentMethodsData from "../assets/data/paymentMethods.json";

const EoGOpVL1 = ({
	geoCode,
	geoData,
	isMobile,
	isTablet,
	translation,
	lang,
}) => {
	const [isLoading, setIsLoading] = useState(false);
	const [nextPath, setNextPath] = useState("");
	const navigate = useNavigate();

	useEffect(() => {
		if (nextPath) {
			setIsLoading(true);
			const beforeNavigationTimer = setTimeout(() => {
				navigate(nextPath);
				window.scrollTo(0, 0);
				setNextPath("");

				const afterNavigationTimer = setTimeout(() => {
					setIsLoading(false);
				}, 3000);
				return () => clearTimeout(afterNavigationTimer);
			}, 2000);

			return () => clearTimeout(beforeNavigationTimer);
		}
	}, [nextPath, navigate]);

	const handleRouteChange = (path) => {
		setNextPath(path);
	};

	return (
		<div>
			<VRF6GlW0
				geoCode={geoCode}
				geoData={geoData}
				translation={translation}
				lang={lang}
				isMobile={isMobile}
				isTablet={isTablet}
				onRouteChange={handleRouteChange}
			/>

			{isLoading && <STXOf2f5 />}

			<Routes>
				<Route
					path="*"
					element={
						<VDyn8ZsI
							geoCode={geoCode}
							translation={translation}
							lang={lang}
							isMobile={isMobile}
							isTablet={isTablet}
							onRouteChange={handleRouteChange}
						/>
					}
				/>
				<Route
					path="offers"
					element={
						<A7TJHBY9
							geoCode={geoCode}
							geoData={geoData}
							translation={translation}
							lang={lang}
							isMobile={isMobile}
							isTablet={isTablet}
							onRouteChange={handleRouteChange}
						/>
					}
				/>
				<Route path="" element={<Ye2IDGUn geoCode={geoCode} lang={lang} />} />
				<Route
					path="restaurant/:restaurantId"
					element={
						<BwyE18DL
							geoCode={geoCode}
							geoData={geoData}
							translation={translation}
							lang={lang}
							isMobile={isMobile}
							isTablet={isTablet}
							onRouteChange={handleRouteChange}
							paymentMethods={paymentMethodsData}
						/>
					}
				/>
				<Route
					path="checkout"
					element={
						<PkBBnrYP
							geoCode={geoCode}
							geoData={geoData}
							translation={translation}
							lang={lang}
							isMobile={isMobile}
							isTablet={isTablet}
							onRouteChange={handleRouteChange}
							paymentMethods={paymentMethodsData}
						/>
					}
				/>
			</Routes>

			<TWxgJJ3P
				geoData={geoData}
				translation={translation}
				lang={lang}
				geoCode={geoCode}
				isMobile={isMobile}
				onRouteChange={handleRouteChange}
			/>
		</div>
	);
};

export default EoGOpVL1;
