import React from "react";
import Ktex1iGy from "./Ktex1iGy";
import RdlKuRQz from "./RdlKuRQz";

const DIlLYniH = ({ geoData, restaurantData, translation, lang }) => {
	const filteredCuisines = geoData.cuisines
		.filter((item) => restaurantData.cuisines.includes(item.id))
		.slice(0, 5);

	const cuisineNames = filteredCuisines
		.map((item) => item.name[lang])
		.join(", ");

	const restaurant = geoData.restaurants.find(
		(restaurant) => restaurant.id === restaurantData.id
	);

	return (
		<div className="jEXH9akd">
			<div className="osIUYhQb">
				<span className="riW7Rt54">{restaurant.name[lang]}</span>
			</div>

			<div className="">
				<div className="zfVuAMtb">
					<div className="PCIdvr1l">
						<span className="RmJ9cErF">{translation.S4KPzTto[lang]}</span>
					</div>

					<div className="PCIdvr1l">
						<span className="d6v9obsK">
							{geoData.currency[lang]} {restaurantData.minOrder.toFixed(2)}
						</span>
					</div>
				</div>

				<div className="zfVuAMtb">
					<div className="PCIdvr1l">
						<span className="RmJ9cErF">{translation.FPlIj63N[lang]}</span>
					</div>

					<div className="PCIdvr1l">
						<span className="d6v9obsK">
							{restaurantData.deliveryTime} {translation.MQyVlyFv[lang]}
						</span>
					</div>
				</div>

				<div className="zfVuAMtb">
					<div className="PCIdvr1l">
						<span className="RmJ9cErF">{translation.Ahaae8va[lang]}</span>
					</div>

					<div className="PCIdvr1l">
						<span className="d6v9obsK">{translation.ksYdzrHV[lang]}</span>
					</div>
				</div>

				<div className="zfVuAMtb">
					<div className="PCIdvr1l">
						<span className="RmJ9cErF">{translation.wBuaLiWY[lang]}</span>
					</div>

					<div className="PCIdvr1l">
						<RdlKuRQz geoData={geoData} translation={translation} />
					</div>
				</div>

				<div className="zfVuAMtb">
					<div className="PCIdvr1l">
						<span className="RmJ9cErF">{translation.nen5endt[lang]}</span>
					</div>

					<div className="PCIdvr1l">
						<Ktex1iGy
							rate={restaurantData.rate}
							translation={translation}
							lang={lang}
						/>
					</div>
				</div>

				<div className="zfVuAMtb">
					<div className="PCIdvr1l">
						<span className="RmJ9cErF">{translation.DJHjQAbM[lang]}</span>
					</div>

					<div className="PCIdvr1l">
						<span className="d6v9obsK">{cuisineNames}</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DIlLYniH;
