import React, { useState } from "react";
import MHbrPQ9C from "./MHbrPQ9C";
import NzR0ABJa from "./NzR0ABJa";

const DxkaSk2I = ({ translation, reviewsData, timezone, lang }) => {
	const [visibleReviews, setVisibleReviews] = useState(3);
	const [isAnimating, setIsAnimating] = useState(false);

	const sortedReviews = [...reviewsData].sort((a, b) => a.day - b.day);

	const handleLoadMore = () => {
		setIsAnimating(true);

		setTimeout(() => {
			setIsAnimating(false);
			setVisibleReviews((prev) => prev + 3);
		}, 500);
	};

	return (
		<div className="aMvC09D6">
			<div className="hB9o4xqH">
				{sortedReviews.slice(0, visibleReviews).map((review) => (
					<MHbrPQ9C
						key={review.id}
						translation={translation}
						review={review}
						timezone={timezone}
						lang={lang}
					/>
				))}
			</div>

			{visibleReviews < sortedReviews.length && (
				<div className="k7RPsWqX" onClick={handleLoadMore}>
					<div className={`F4Sh3DPW ${isAnimating ? "RhsIEWjH" : "gYQ0fiaK"}`}>
						<NzR0ABJa />
					</div>

					<span className="ikjx1E8f">{translation.oEkQ8Y5a[lang]}</span>
				</div>
			)}
		</div>
	);
};

export default DxkaSk2I;
