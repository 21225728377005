import React, { useEffect, useRef, useState } from "react";

const useIntersectionObserver = (element, callback, options) => {
	useEffect(() => {
		const observer = new IntersectionObserver(callback, options);

		if (element.current) observer.observe(element.current);

		return () => {
			if (element.current) observer.unobserve(element.current);
		};
	}, [element, callback, options]);
};

const OYAImWDC = ({ img }) => {
	const [isVisible, setIsVisible] = useState(false);
	const imageRef = useRef(null);

	const handleIntersection = (entries) => {
		entries.forEach((entry) => {
			if (entry.isIntersecting) {
				setIsVisible(true);
			}
		});
	};

	useIntersectionObserver(imageRef, handleIntersection, { threshold: 0.1 });

	return (
		<div
			className="iuRDB7No"
			style={{
				backgroundImage: `url(${isVisible ? "" : "/assets/img/eifDBjMW.svg"})`,
			}}
		>
			<div
				className={`hIxveBbl fDRncnMU jfnRxL8O ANzxpbac ${
					isVisible ? "q6BkvhfL" : "AXDu8WkP"
				}`}
				ref={imageRef}
				style={{
					backgroundImage: `url(${img})`,
				}}
				loading="lazy"
			></div>
		</div>
	);
};

export default OYAImWDC;
