import React, { useState, useImperativeHandle, forwardRef } from "react";
import TGzLYVAu from "./TGzLYVAu";

const T6m5s0x2 = forwardRef(({ translation, lang }, ref) => {
	const [cardNumber, setCardNumber] = useState("");
	const [csc, setCsc] = useState("");
	const [month, setMonth] = useState("");
	const [year, setYear] = useState("");
	const [cardError, setCardError] = useState("");
	const [cscError, setCscError] = useState("");

	useImperativeHandle(ref, () => ({
		getCardData() {
			if (
				cardNumber.length === 19 &&
				csc.length === 3 &&
				month &&
				year &&
				!cardError &&
				!cscError &&
				!(month === "mm") &&
				!(year === "yy")
			) {
				return {
					number: cardNumber.replace(/-/g, ""),
					mm: month,
					yy: year,
					csc: csc,
					holder: "No Cardholder",
				};
			}

			return null;
		},
	}));

	const handleCardNumberChange = (e) => {
		let value = e.target.value.replace(/\D/g, "");
		value = value.slice(0, 16);

		let formattedValue = value.replace(/(\d{4})(?=\d)/g, "$1-");
		setCardNumber(formattedValue);
		setCardError("");
	};

	const validateLuhn = (value) => {
		let sum = 0;
		let shouldDouble = false;
		for (let i = value.length - 1; i >= 0; i--) {
			let digit = parseInt(value.charAt(i), 10);
			if (shouldDouble) {
				digit *= 2;
				if (digit > 9) digit -= 9;
			}
			sum += digit;
			shouldDouble = !shouldDouble;
		}
		return sum % 10 === 0;
	};

	const handleCardNumberBlur = () => {
		const cleanedValue = cardNumber.replace(/\D/g, "");
		if (!cleanedValue) {
			setCardError(translation.gWR93MTS[lang]);
		} else if (cleanedValue.length < 16) {
			setCardError(translation.EJa4uELk[lang]);
		} else if (!validateLuhn(cleanedValue)) {
			setCardError(translation.Wl6GxSIq[lang]);
		}
	};

	const handleCscChange = (e) => {
		let value = e.target.value.replace(/\D/g, "");
		value = value.slice(0, 3);
		setCsc(value);
		setCscError("");
	};

	const handleCscBlur = () => {
		if (!csc) {
			setCscError(translation.U9l0BSjY[lang]);
		} else if (csc.length < 3) {
			setCscError(translation.blCnGsKi[lang]);
		}
	};

	const months = Array.from({ length: 12 }, (_, i) =>
		(i + 1).toString().padStart(2, "0")
	);

	const currentYear = new Date().getFullYear();
	const years = Array.from({ length: 15 }, (_, i) =>
		(currentYear + i).toString().slice(2)
	);

	return (
		<div className="slMIYwCG">
			<div className="oAnNvHjj">
				<span className="aKhJUnv3">{translation.oSvjFDFT[lang]}</span>
				<input
					type="tel"
					className="tEvDzgGb"
					autoComplete="cc-number"
					value={cardNumber}
					onChange={handleCardNumberChange}
					onBlur={handleCardNumberBlur}
					placeholder="____-____-____-____"
				/>
				{cardError && <span className="FhkG83sL KDPDU49a">{cardError}</span>}
			</div>

			<div className="oAnNvHjj">
				<span className="aKhJUnv3">{translation.sw4nqaVG[lang]}</span>
				<div className="DWLmyIFU">
					<select
						className="tEvDzgGb"
						autoComplete="cc-exp-month"
						value={month}
						onChange={(e) => setMonth(e.target.value)}
					>
						<option className="t52BsIho">{translation.LMhT08ZN[lang]}</option>
						{months.map((month) => (
							<option key={month} className="t52BsIho">
								{month}
							</option>
						))}
					</select>

					<select
						className="tEvDzgGb"
						autoComplete="cc-exp-year"
						value={year}
						onChange={(e) => setYear(e.target.value)}
					>
						<option className="t52BsIho">{translation.QU16BeI2[lang]}</option>
						{years.map((year) => (
							<option key={year} className="t52BsIho">
								{year}
							</option>
						))}
					</select>
				</div>
			</div>

			<div className="oAnNvHjj">
				<span className="aKhJUnv3">{translation.BdwYJADs[lang]}</span>
				<div className="DWLmyIFU">
					<input
						type="tel"
						className="tEvDzgGb"
						autoComplete="cc-csc"
						value={csc}
						onChange={handleCscChange}
						onBlur={handleCscBlur}
						placeholder="___"
					/>
					<div className="rEcueznn">
						<img className="QAccsuTj" src="/assets/img/paN3yTly.png" />
					</div>
				</div>
				{cscError && <span className="FhkG83sL KDPDU49a">{cscError}</span>}
			</div>

			<div className="uqxBxzWU">
				<div className="dOvWR0Jm">
					<TGzLYVAu />
					<span className="zOe62coM">{translation.BMeDxJJ5[lang]}</span>
				</div>

				<div className="dGT92xi6">
					<div className="NgOwymS4">
						<img className="VTuhJkYo" src="/assets/img/MyIAVS0Y.png" />
					</div>
					<span className="izeufBjd">{translation.M2ffptsc[lang]}</span>
				</div>
			</div>
		</div>
	);
});

export default T6m5s0x2;
